
import { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { LeadTypes } from '@/enums/leadtypes.enum';
import Api from '@/functions/api';
import Format from '@/functions/format';
import tracking from '@/functions/tracking';
import Broker from '@/interfaces/broker.interface';
import FormSettings from '@/interfaces/formSettings.interface';
import Dawa from '@/interfaces/dawa.interface';
import CurrentUser from '@/interfaces/currentUser.interface';

type FormErrors = {
    name?: string;
    mail?: string;
    phone?: string;
    message?: string;
    address?: string;
};

type Consent = {
    purposeText: string;
    id: string;
    heading: string;
    leadType: {
        displayName: string;
    };
};

interface Data {
    isSignupSuccessful: boolean;
    errors: FormErrors;
    isLoading: boolean;
    form: {
        name: string;
        email: string;
        phone: string;
        message?: string;
    };
    isFormInitiated: boolean;
    responsibleBroker: undefined | Broker;
    addressFound: boolean;
    address: string;
    dawa: Dawa | undefined;
    isFocused: boolean;
    addressId: string;
    zipCode: string;
    propertyEstimateError: boolean;
}

type ContactBrokerModalWithAddressData = {
    headline: string;
    leadingParagraph: string;
    ctaText: string;
    ctaAltText: string;
    formHeadline: string;
    formText: string;
    formLeadText: string;
    formCtaText: string;
    formCtaAltText: string;
    formReceiptHeadline: string;
    formReceiptText: string;
    consent: Consent;
    formSettings: FormSettings;
};

export default {
    props: {
        user: {
            type: Object as PropType<CurrentUser>,
            default: () => ({
                name: '',
                email: '',
                phone: '',
            }),
        },
        curtainData: {
            type: Object as PropType<ContactBrokerModalWithAddressData>,
            default: () => ({
                headline: '',
                leadingParagraph: '',
                ctaText: '',
                ctaAltText: '',
                formHeadline: '',
                formText: '',
                formLeadText: '',
                formCtaText: '',
                formCtaAltText: '',
                formReceiptHeadline: '',
                formReceiptText: '',
                consent: {
                    purposeText: '',
                    id: '',
                    heading: '',
                    leadType: {
                        displayName: '',
                    },
                },
                formSettings: {
                    msgPlaceholder: '',
                    msgErrorMsg: '',
                    namePlaceholder: '',
                    nameErrorMsg: '',
                    addressPlaceholder: '',
                    addressErrorMsg: '',
                    mailPlaceholder: '',
                    mailErrorMsg: '',
                    phonePlaceholder: '',
                    phoneErrorMsg: '',
                    offerPlaceholder: '',
                    offerErrorMsg: '',
                    linkText: '',
                    link: null,
                    submitButtonText: '',
                    brokerErrorMsg: '',
                    heading: '',
                    bodytext: '',
                    commentPlaceholder: '',
                    commentErrorMsg: '',
                    datePlaceholder: '',
                    dateErrorMsg: '',
                    municipalityPlaceholder: '',
                    municipalityErrorMsg: '',
                    desiredAddressPlaceholder: '',
                    consentErrorMsg: '',
                },
            }),
        },
    },

    data(): Data {
        return {
            isSignupSuccessful: false,
            form: {
                name: this.user?.name ?? '',
                email: this.user?.email ?? '',
                phone: '',
                message: '',
            },
            isLoading: false,
            errors: {},
            isFormInitiated: false,
            responsibleBroker: undefined,
            isFocused: false,
            address: '',
            addressFound: false,
            dawa: undefined,
            addressId: '',
            zipCode: '',
            propertyEstimateError: false,
        };
    },

    computed: {
        ...mapGetters({
            show: 'modal/show',
            type: 'modal/type',
            trackingEntrance: 'modal/trackingEntrance',
            utmSource: 'document/utmSource',
            utmMedium: 'document/utmMedium',
            utmCampaign: 'document/utmCampaign',
            utmContent: 'document/utmContent',
        }),

        showModal(): boolean {
            return this.show && this.type === 'brokerContactWithAddress';
        },

        receiptText(): string {
            return this.curtainData.formReceiptText.replace(
                '#adresse#',
                this.address,
            );
        },
    },

    watch: {
        form: {
            deep: true,
            handler() {
                this.errors = {};
                if (!this.isFormInitiated && this.showModal) {
                    tracking.trackRawEvent({
                        event: 'trackForm',
                        eventData: {
                            category: 'Formular',
                            action: 'Salgsvurdering initieret',
                            label: 'Salgsvurdering',
                            formularIndgang: this.trackingEntrance,
                            formularStepnavn: 'Brugerinformation',
                        },
                    });
                    this.isFormInitiated = true;
                }
            },
        },
        isFocused: {
            handler(newValue) {
                if (newValue && !this.isFormInitiated) {
                    this.isFormInitiated = true;
                    tracking.trackRawEvent({
                        event: 'trackForm',
                        eventData: {
                            category: 'Formular',
                            action: 'Salgsvurdering initieret',
                            label: 'Salgsvurdering',
                            formularIndgang: this.trackingEntrance,
                            formularStepnavn: 'Brugerinformation',
                        },
                    });
                }
            },
            immediate: false,
        },
        showModal: {
            handler() {
                this.isFormInitiated = false;
                if (!this.showModal) {
                    this.propertyEstimateError = false;
                    if (this.isSignupSuccessful) {
                        this.isSignupSuccessful = false;
                        this.form = {
                            name: this.user?.name ?? '',
                            email: this.user?.email ?? '',
                            phone: '',
                            message: '',
                        };
                        this.address = '';
                        this.addressId = '';
                        this.zipCode = '';
                        this.responsibleBroker = undefined;
                    }
                }
            },
        },
    },

    methods: {
        ...mapActions({
            openModal: 'modal/open',
            setTrackingEntrance: 'modal/setTrackingEntrance',
        }),

        enableSubmit(): void {
            this.addressFound = true;
        },

        disableSubmit(): void {
            this.addressFound = false;
        },

        setIsFocused(value: boolean): void {
            this.isFocused = value;
        },

        setAddressInfo(value: Dawa): void {
            this.propertyEstimateError = false;
            this.address = '';

            if (this.addressFound && value.data) {
                this.dawa = value;
                this.addressId = value.data.id;
                this.zipCode = value.data.postnr;
                this.address = value.tekst;
            }
        },

        async getResponsibleBroker() {
            let response = await Api.getResponsibleBroker({
                addressId: this.addressId,
                zipCode: this.zipCode,
            });
            this.responsibleBroker = {
                ...response.data,
                email: undefined,
                phone: undefined,
            };
        },

        async checkIfPropertyHasEstimate() {
            if (this.addressId) {
                Api.hasPropertyEstimate({
                    addressId: this.addressId,
                })
                    .then((response: any) => {
                        this.propertyEstimateError = !response.exists;
                        if (response.exists === true) {
                            this.onSubmitForm();
                        }
                    })
                    .catch(() => {
                        this.propertyEstimateError = true;
                    });
            } else {
                this.propertyEstimateError = true;
            }
        },

        onSubmitForm(): void {
            const type = LeadTypes.SalesValuationHomeEstimate.toString();
            const excludeValidation = ['message'];
            this.isLoading = true;
            const message = this.form.message
                ? `${this.form.message}\r\n\r\n${this.curtainData.formLeadText}`
                : this.curtainData.formLeadText;

            Api.postLead(
                {
                    consentIdGlobal: this.curtainData.consent.id,
                    address: this.address,
                    email: this.form.email.trim() ?? '',
                    firstName: Format.firstName(this.form.name) ?? '',
                    lastName: Format.lastName(this.form.name) ?? '',
                    message,
                    phoneNumber: this.form.phone ?? '',
                    responsibleShopNo: this.responsibleBroker?.brokerId ?? '',
                    responsibleEmployeeIdGlobal:
                        this.responsibleBroker?.employeeId,
                    type,
                    utmCampaign: this.utmCampaign,
                    utmContent: this.utmContent,
                    utmMedium: this.utmMedium,
                    utmSource: this.utmSource,
                },
                excludeValidation,
            )
                .then((): void => {
                    this.isSignupSuccessful = true;
                    this.isLoading = false;

                    this.getResponsibleBroker();

                    tracking.trackRawEvent({
                        event: 'trackForm',
                        eventData: {
                            category: 'Formular',
                            action: 'Salgsvurdering gennemfoert',
                            label: 'Salgsvurdering',
                            formularIndgang: this.trackingEntrance,
                            formularStepnavn: 'Kvittering',
                        },
                    });
                })
                .catch((error: any): void => {
                    this.errors = error.errors ?? [];
                })
                .finally((): void => {
                    this.isLoading = false;
                });
        },
    },
};
