import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["src", "srcset", "width", "height", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_skeleton_loader = _resolveComponent("m-skeleton-loader")!
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    ($data.isLoading)
      ? (_openBlock(), _createBlock(_component_m_skeleton_loader, {
          key: 0,
          name: "gallerySkeletonLoader"
        }))
      : _createCommentVNode("v-if", true),
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          (_openBlock(), _createElementBlock("img", {
            key: `thumbnail-${$props.index}`,
            class: "o-propertyGallery__galleryImageListImage",
            src: $options.thumbnailSrc,
            srcset: $options.thumbnailSrcSet,
            width: `${$props.width}`,
            height: `${$props.height}`,
            alt: $props.alt,
            onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onLoad && $options.onLoad(...args)))
          }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1)),
          ($props.type === 'Video')
            ? (_openBlock(), _createBlock(_component_a_icon, {
                key: 0,
                name: "play"
              }))
            : _createCommentVNode("v-if", true)
        ])
      ], undefined, true),
      _: 1 /* STABLE */
    })
  ]))
}