import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "o-homeEstimateLandingHero__inputSectionTexts" }
const _hoisted_2 = { class: "o-homeEstimateLandingHero__contentDescription" }
const _hoisted_3 = { class: "a-lead" }
const _hoisted_4 = { class: "o-homeEstimateLandingHero__contentDescriptionIcon" }
const _hoisted_5 = ["aria-label"]
const _hoisted_6 = { class: "o-homeEstimateLandingHero__inputWrapper" }
const _hoisted_7 = { class: "o-homeEstimateLandingHero__disclaimer" }
const _hoisted_8 = ["textContent"]
const _hoisted_9 = { class: "o-homeEstimateLandingHero__buttonWrapper" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = ["textContent"]
const _hoisted_12 = { class: "o-panel" }
const _hoisted_13 = { class: "a-heading2 o-panel__title o-panel__heading o-homeEstimateLandingHero__infoHeadline" }
const _hoisted_14 = { class: "o-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!
  const _component_m_input = _resolveComponent("m-input")!
  const _component_portal = _resolveComponent("portal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, [
          _renderSlot(_ctx.$slots, "description")
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            type: "button",
            class: "a-infoButton",
            "aria-label": $props.content.ariaLabel,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onClickEstimateTeaserInfo && $options.onClickEstimateTeaserInfo(...args)))
          }, [
            _createVNode(_component_a_icon, { name: "info" })
          ], 8 /* PROPS */, _hoisted_5)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_m_input, {
        value: $data.address,
        class: "m-input__field o-homeEstimateLandingHero__contentInput",
        name: "address‌_search",
        error: $data.error ? $props.content.errorMessage : '',
        label: $props.content.placeholder,
        theme: "",
        type: "dawa",
        onDawa: $options.enableSubmit,
        onDawaReset: $options.disableSubmit,
        onInput: $options.setAddressInfo,
        onFocus: _cache[1] || (_cache[1] = ($event: any) => ($options.setIsFocused(true))),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => ($options.setIsFocused(false)))
      }, null, 8 /* PROPS */, ["value", "error", "label", "onDawa", "onDawaReset", "onInput"]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", {
          class: "a-small o-homeEstimateLandingHero__disclaimerText",
          textContent: _toDisplayString($props.content.helpText)
        }, null, 8 /* PROPS */, _hoisted_8)
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("button", {
        type: "submit",
        class: _normalizeClass(["m-button o-homeEstimateLandingHero__contentInputButton", { isLoading: $data.isLoading }]),
        disabled: !$data.addressFound || !$data.address,
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($options.onSubmit && $options.onSubmit(...args)))
      }, [
        _createElementVNode("span", {
          class: "m-button__text",
          textContent: _toDisplayString($props.content.buttonText)
        }, null, 8 /* PROPS */, _hoisted_11)
      ], 10 /* CLASS, PROPS */, _hoisted_10)
    ]),
    ($options.showEstimateTeaserInfo)
      ? (_openBlock(), _createBlock(_component_portal, {
          key: 0,
          to: "modal"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("h2", _hoisted_13, [
                _renderSlot(_ctx.$slots, "info-headline")
              ]),
              _createElementVNode("div", _hoisted_14, [
                _renderSlot(_ctx.$slots, "info-description")
              ])
            ])
          ], undefined, true),
          _: 3 /* FORWARDED */
        }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}