
import { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Widget } from '@/store/widget/interface';
import Format from '@/functions/format';
import tracking from '@/functions/tracking';

interface Data {
    isShown: boolean;
    mustShow: boolean;
}

export interface Texts {
    isHomeEstimateSticker: boolean;
    loadTime: {
        before: number;
        after: number;
    };
    showAgain: {
        before: number;
        after: number;
    };
    headline: {
        before: string;
        after: string;
    };
    trackingLabelTekst?: string;
    description: string;
    bgcolor: {
        before: string;
        after: string;
    };
    button: {
        url: {
            before: string;
            after: string;
        };
        text: {
            after: string;
        };
        screenReaderText: {
            before: string;
            after: string;
        };
    };
    icon: string;
}

export default {
    name: 'm-cta-widget',
    props: {
        name: {
            type: String as PropType<string>,
            default: () => '',
        },
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                isHomeEstimateSticker: false,
                loadTime: {
                    before: 0,
                    after: 0,
                },
                showAgain: {
                    before: 0,
                    after: 0,
                },
                headline: {
                    before: '',
                    after: '',
                },
                trackingLabelTekst: '',
                description: '',
                bgcolor: {
                    before: '',
                    after: '',
                },
                button: {
                    url: {
                        before: '',
                        after: '',
                    },
                    text: {
                        after: '',
                    },
                    screenReaderText: {
                        before: '',
                        after: '',
                    },
                },
                icon: '',
            }),
        },
    },

    data(): Data {
        return {
            isShown: false,
            mustShow: false,
        };
    },

    computed: {
        ...mapGetters({
            widgets: 'widget/widgets',
            current: 'widget/current',
            userEstimate: 'estimate/userEstimate',
            brokerImage: 'estimate/brokerImage',
            address: 'estimate/address',
        }),

        /**
         * Check if the component has an illustration.
         *
         * @return {boolean}
         */
        showIcon(): boolean {
            if (this.userEstimate > 0 && this.texts.isHomeEstimateSticker) {
                return false;
            }

            return this.texts.icon !== '';
        },

        iconUrl(): string {
            return `${this.texts.icon}?width=112`;
        },

        stickerUrl(): string {
            if (this.userEstimate > 0 && this.texts.isHomeEstimateSticker) {
                return this.texts.button.url.after;
            }
            return this.texts.button.url.before;
        },

        stickerButtonText(): string {
            if (this.userEstimate > 0 && this.texts.isHomeEstimateSticker) {
                return this.texts.button.text.after;
            }
            return '';
        },

        stickerScreenReaderText(): string {
            if (this.userEstimate > 0 && this.texts.isHomeEstimateSticker) {
                return this.texts.button.screenReaderText.after;
            }
            return this.texts.button.screenReaderText.before;
        },

        /**
         * Define modifier class.
         *
         * @return {string}
         */
        modifierClass(): string {
            if (this.userEstimate > 0 && this.texts.isHomeEstimateSticker) {
                return `m-ctaWidget--${this.texts.bgcolor.after}`;
            }
            return `m-ctaWidget--${this.texts.bgcolor.before}`;
        },

        headlineText(): string {
            if (this.userEstimate > 0 && this.texts.isHomeEstimateSticker) {
                return `${this.texts.headline.after} <span>${Format.amount(this.userEstimate)}&nbsp;kr.</span>`;
            }
            return this.texts.headline.before;
        },

        descriptionText(): string {
            if (this.userEstimate > 0 && this.texts.isHomeEstimateSticker) {
                return `${this.address}`;
            }
            return this.texts.description;
        },
    },

    beforeMount(): void {
        const widgets = this.widgets as Widget[];
        const singleWidgetIndex = widgets.findIndex(
            (item: Widget) => item.name === this.name,
        );

        if (singleWidgetIndex > -1) {
            const currentTimestamp = Date.now();
            const widgetTimestamp = this.widgets[singleWidgetIndex].timestamp;
            const difference = currentTimestamp - widgetTimestamp;
            const differenceInMinutes = new Date(difference).getMinutes();
            const compValue =
                this.userEstimate > 0
                    ? this.texts.showAgain.after
                    : this.texts.showAgain.before;

            if (differenceInMinutes >= compValue) {
                this.delete();
                this.mustShow = true;
            } else {
                this.mustShow = false;
            }
        } else {
            this.mustShow = true;
        }

        if (this.mustShow) {
            tracking.track(
                'trackSticker',
                'Sticker',
                'Impression',
                this.texts.trackingLabelTekst ||
                    this.headlineText.replace('<br />', ''),
            );
        }
    },

    mounted(): void {
        if (!!this.name && this.mustShow) {
            this.setCurrent(this.name);

            const compValue =
                this.userEstimate > 0
                    ? this.texts.loadTime.after
                    : this.texts.loadTime.before;

            if (compValue > 0) {
                setTimeout(() => {
                    this.isShown = !this.current;
                }, compValue);
            } else {
                this.isShown = !this.current;
            }
        } else {
            this.isShown = false;
        }
    },

    methods: {
        ...mapActions({
            dismissWidget: 'widget/dismissWidget',
            setCurrent: 'widget/setCurrent',
            deleteWidget: 'widget/deleteWidget',
        }),

        dismiss(): void {
            tracking.track(
                'trackSticker',
                'Sticker',
                'Sticker lukket',
                this.texts.trackingLabelTekst ||
                    this.headlineText.replace('<br />', ''),
            );

            this.dismissWidget({
                name: this.name,
                timestamp: Date.now(),
            });
            this.isShown = false;
        },

        delete(): void {
            this.deleteWidget({
                name: this.name,
            });
        },

        track(): void {
            tracking.track(
                'trackSticker',
                'Sticker',
                'Click',
                this.texts.trackingLabelTekst ||
                    this.headlineText.replace('<br />', ''),
            );
        },
    },
};
