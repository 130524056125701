import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-media hasVideo hasYouTubeVideo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_youtube = _resolveComponent("youtube")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_youtube, {
      ref: "youtube",
      "video-id": $props.youtubeId,
      nocookie: true,
      onPlaying: $options.playing
    }, null, 8 /* PROPS */, ["video-id", "onPlaying"])
  ]))
}