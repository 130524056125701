
// @ts-nocheck
import Vue, { PropType } from 'vue';
import tracking from '@/functions/tracking';
import { Breakpoints } from '@/enums/breakpoints.enum';
import { DEG720, SLIDESHOW } from '@/constants/mediaTypes.const';
import { EventBus } from '@/functions/eventBus';
import GalleryItem from '@/interfaces/galleryItem.interface';
import mLoader from '@/components/molecules/Loader.vue';

interface Data {
    currentIndex: number;
    isVisible: boolean;
    galleryItems: GalleryItem[];
    isDesktop: boolean;
    hasTouch: boolean;
    initialLoad: boolean;
    isLoading: boolean;
    slideshowType: string;
    deg720Type: string;
}

export default {
    name: 'o-property-interactive-gallery',
    components: {
        mLoader,
    },
    props: {
        propertyId: {
            type: String as PropType<string>,
            default: () => '',
        },
        brokerId: {
            type: String as PropType<string>,
            default: () => '',
        },
        vr: {
            type: Array as PropType<GalleryItem[]>,
            default: () => [],
        },
        vrThumbnailText: {
            type: String as PropType<string>,
            default: () => '',
        },
    },

    data(): Data {
        return {
            currentIndex: 0,
            isVisible: false,
            initialLoad: true,
            galleryItems: [...this.vr],
            isDesktop: EventBus.isDesktop,
            hasTouch: EventBus.hasTouch,
            isLoading: false,
            slideshowType: SLIDESHOW,
            deg720Type: DEG720,
        };
    },

    computed: {
        vrThumbnail(): string {
            return this.vrThumbnailText;
        },
        /**
         * Get the id of the active gallery item.
         *
         * @return {string}
         */
        currentId(): string {
            return this.galleryItems[this.currentIndex]?.id;
        },

        /**
         * Get the active gallery item.
         *
         * @return {GalleryItem}
         */
        currentGalleryItem(): GalleryItem {
            return this.galleryItems[this.currentIndex];
        },

        /**
         * Get the source for the active gallery item.
         *
         * @return {string}
         */
        currentPoster(): string {
            return this.galleryItems[this.currentIndex]?.previewUrl;
        },

        /**
         * Get the source for the active gallery item.
         *
         * @return {string}
         */
        currentSrc(): string {
            return this.galleryItems[this.currentIndex]?.url.toString();
        },

        /**
         * Get the width for the active gallery item.
         *
         * @return {string}
         */
        currentWidth(): string {
            return this.galleryItems[this.currentIndex]?.width.toString();
        },

        /**
         * Get the height for the active gallery item.
         *
         * @return {string}
         */
        currentHeight(): string {
            return this.galleryItems[this.currentIndex]?.height.toString();
        },

        /**
         * Get the appropriate dimensions for the gallery thumbnails.
         *
         * @return {string[]}
         */
        dimensions(): string[] {
            if (window.innerWidth >= Breakpoints.lg && this.hasTouch) {
                return ['width=200', 'width=400'];
            }

            if (window.innerWidth >= Breakpoints.sm && this.hasTouch) {
                return ['height=144', 'height=288'];
            }

            if (this.isDesktop) {
                return ['width=200', 'width=400'];
            }

            return ['height=144', 'height=288'];
        },

        isMobile(): Boolean {
            return !EventBus.isDesktop;
        },
    },

    mounted(): void {
        let esoftpanoramaScript = document.createElement('script');
        esoftpanoramaScript.setAttribute(
            'src',
            'https://cdn-m2.esoftsystems.com/panorama/esoftpanorama.js',
        );
        esoftpanoramaScript.setAttribute('importance', 'high');
        document.head.appendChild(esoftpanoramaScript);

        EventBus.$on('app.keyup', (keycode: string) => {
            switch (keycode) {
                case 'Escape':
                    this.close();
                    break;
                case 'ArrowUp':
                case 'ArrowLeft':
                    this.prev();
                    break;
                case 'ArrowDown':
                case 'ArrowRight':
                    this.next();
                    break;
                default:
                    break;
            }
        });
    },

    beforeUnmount(): void {
        EventBus.$off('app.keyup', this.close);
        EventBus.$off('app.keyup', this.prev);
        EventBus.$off('app.keyup', this.next);
    },

    methods: {
        /**
         * Close the gallery.
         *
         * @return {void}
         */
        close(): void {
            document.body.style.overflow = '';
            this.currentIndex = 0;
            this.isVisible = false;
            this.isLoading = false;
        },

        /**
         * Send gallery change event to GTM
         *
         * @return {void}
         */
        trackGalleryChange(): void {
            tracking.track(
                'trackBoligpraesentation',
                'Boligpraesentation',
                'Virtuel rundvisning',
                this.propertyId,
            );
        },

        /**
         * Increment index.
         *
         * @return {void}
         */
        next(): void {
            this.isLoading = true;
            if (this.currentIndex < this.galleryItems.length - 1) {
                this.currentIndex += 1;
            } else {
                this.currentIndex = 0;
            }
            this.scroll();
            this.trackGalleryChange();
        },

        /**
         * Handle "click" event on thumbnails.
         *
         * @param {MouseEvent} event
         * @param {number} index
         * @return {void}
         */
        onClick(index: number): void {
            if (this.currentIndex !== index) {
                this.currentIndex = index;
                this.isLoading = true;
                this.scroll();
                this.trackGalleryChange();
            }
        },

        /**
         * Decrease index.
         *
         * @return {void}
         */
        prev(): void {
            this.isLoading = true;
            if (this.currentIndex > 0) {
                this.currentIndex -= 1;
            } else {
                this.currentIndex = this.galleryItems.length - 1;
            }
            this.scroll();
            this.trackGalleryChange();
        },

        /**
         * Scroll to active thumbnail.
         *
         * @param {MouseEvent} event
         * @return {void}
         */
        scroll(): void {
            let element = document.getElementById(this.currentId);

            if (element) {
                if (window.innerWidth < Breakpoints.lg && this.hasTouch) {
                    element?.scrollIntoView({ inline: 'center' });
                } else if (this.isDesktop) {
                    let { bottom: imageBottom, top: imageTop } = (
                        this.$refs.galleryImage as HTMLDivElement
                    ).getBoundingClientRect();
                    let { bottom: thumbnailBottom, top: thumbnailTop } =
                        element.getBoundingClientRect();

                    if (imageTop > thumbnailTop) {
                        (
                            this.$refs.galleryImageList as HTMLDivElement
                        )?.scrollBy({
                            top: thumbnailTop - imageTop,
                            behavior: 'smooth',
                        });
                    }

                    if (imageBottom < thumbnailBottom) {
                        (
                            this.$refs.galleryImageList as HTMLDivElement
                        )?.scrollBy({
                            top: thumbnailBottom - imageBottom,
                            behavior: 'smooth',
                        });
                    }
                }
            }

            this.handlePanorama();
        },

        handlePanorama(): void {
            this.$nextTick(() => {
                const panorama = this.$refs.panoramaWrapper as HTMLDivElement;
                let panoramaId = `panorama${Math.random()}`;

                if (panorama) {
                    panorama.innerHTML = '';
                    const panoramaDiv = document.createElement('div');
                    panoramaDiv.setAttribute('id', panoramaId);
                    panorama.appendChild(panoramaDiv);
                    let url = `${
                        this.galleryItems[this.currentIndex].url
                    }?${Math.random()}`;
                    // eslint-disable-next-line
                    loadEsoftPanorama(panoramaId, 'html5', url, '100%', '100%');
                    this.isLoading = false;
                }
            });
        },

        /**
         * Open the interactive gallery on the provided index.
         *
         * @param {number} index
         * @return {void}
         */
        show(index: number): void {
            document.body.style.overflow = 'hidden';
            this.currentIndex = index;
            this.isVisible = true;

            if (this.initialLoad === true) {
                this.initialLoad = false;
                setTimeout(() => {
                    this.scrollCurrentIntoView();
                    this.handlePanorama();
                }, 1000);
            } else {
                Vue.nextTick(() => {
                    this.scrollCurrentIntoView();
                    this.handlePanorama();
                });
            }

            tracking.track(
                'trackBoligpraesentation',
                'Boligpraesentation',
                'Virtuel rundvisning start',
                this.propertyId,
                { maeglerId: this.brokerId },
            );
        },

        /**
         * Scrolls the current element of the list into view.
         *
         * @return {void}
         */
        scrollCurrentIntoView(): void {
            const elem = document.getElementById(this.currentId);
            if (elem) {
                elem.scrollIntoView({ inline: 'center' });
            }
        },

        /**
         * Adds all needed event listeners on transition after-enter
         *
         * @return {void}
         */
        addListeners(): void {
            if (window.innerWidth < Breakpoints.xl) {
                EventBus.$on('app.scroll', () => {
                    window.scrollTo(0, 0);
                });
            }
        },

        /**
         * Removes all needed event listeners on transition after-leave
         *
         * @return {void}
         */
        removeListeners(): void {
            EventBus.$off('app.scroll');
        },
    },
};
