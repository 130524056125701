
import { PropType } from 'vue';
import { mapGetters } from 'vuex';
import Dawa from '@/interfaces/dawa.interface';
import LeadConsent from '@/interfaces/leadConsent.interface';
import CurrentUser from '@/interfaces/currentUser.interface';
import { EventBus } from '@/functions/eventBus';
import Api from '@/functions/api';
import { LeadTypes } from '@/enums/leadtypes.enum';
import Format from '@/functions/format';
import tracking from '@/functions/tracking';

export interface Data {
    form: {
        address: string;
        name: string;
        phone: string;
        mail?: string;
    };
    errors: {
        address?: string;
        name?: string;
        phone?: string;
        mail?: string;
    };
    addressFound: boolean;
    zipcode?: number;
    isFocused: boolean;
    isLoading: boolean;
    step: number;
    isDesktop: boolean;
    isFormInitiated: boolean;
}

export interface Texts {
    trumpet: string;
    headline: string;
    body: string;
    buttonText: string;
    receiptTrumpet: string;
    receiptHeadline: string;
    receiptBody: string;
}

export interface FormSettings {
    addressPlaceholder: string;
    namePlaceholder: string;
    phonePlaceholder: string;
    mailPlaceholder: string;
}

export default {
    props: {
        user: {
            type: Object as PropType<CurrentUser>,
            default: () => ({
                name: '',
                email: '',
                phone: '',
            }),
        },
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                trumpet: '',
                headline: '',
                body: '',
                buttonText: '',
                receiptTrumpet: '',
                receiptHeadline: '',
                receiptBody: '',
            }),
        },
        formSettings: {
            type: Object as PropType<FormSettings>,
            default: () => ({
                addressPlaceholder: '',
                namePlaceholder: '',
                phonePlaceholder: '',
                mailPlaceholder: '',
            }),
        },
        brokerId: {
            type: String as PropType<string>,
            default: () => '',
        },
        consent: {
            type: Object as PropType<LeadConsent>,
            default: () => ({
                id: '',
                purposeText: '',
            }),
        },
    },

    data(): Data {
        return {
            form: {
                address: '',
                name: this.user?.name ?? '',
                mail: this.user?.email ?? '',
                phone: '',
            },
            errors: {},
            isFocused: false,
            isLoading: false,
            addressFound: false,
            step: 1,
            isDesktop: EventBus.isDesktop,
            isFormInitiated: false,
        };
    },

    computed: {
        ...mapGetters({
            referrer: 'document/referrer',
            utmSource: 'document/utmSource',
            utmMedium: 'document/utmMedium',
            utmCampaign: 'document/utmCampaign',
            utmContent: 'document/utmContent',
        }),
        /**
         * Step one disable status for submit button
         *
         * @return {boolean}
         */
        stepOneValidation(): boolean {
            return (
                !this.addressFound ||
                !this.form.address ||
                !this.form.name ||
                !this.form.phone
            );
        },

        /**
         * Format confirmation headline
         *
         * @return {string}
         */
        formattedConfirmation(): string {
            return this.texts.receiptHeadline.replace(
                '#adresse#',
                this.form.address,
            );
        },
    },

    watch: {
        form: {
            deep: true,
            handler() {
                this.errors = {};
                if (!this.isFormInitiated) {
                    tracking.trackRawEvent({
                        event: 'trackForm',
                        eventData: {
                            category: 'Formular',
                            action: 'Salgsvurdering indgang',
                            label: 'Salgsvurdering',
                            formularStepnavn: 'Brugerinformation',
                            formularIndgang: 'Aaben formular',
                            maeglerId: this.brokerId ?? '',
                        },
                    });
                    this.isFormInitiated = true;
                }
            },
        },
    },

    mounted(): void {
        const hash = document.location.hash.replace('#', '');
        if (hash) {
            const el = document.querySelector(
                `div[id="${hash}"]`,
            ) as HTMLDivElement;
            if (el) el.scrollIntoView();
        }
    },

    beforeMount() {
        EventBus.$on('app.resize', () => {
            this.isDesktop = EventBus.isDesktop;
        });
    },

    methods: {
        /**
         * Set whether input field is focused
         *
         * @return {void}
         */
        setIsFocused(value: boolean): void {
            this.isFocused = value;
        },

        /**
         * Increment form step
         *
         * @return {void}
         */
        incrementStep(): void {
            this.step += 1;
        },

        /**
         * Enable submit button.
         *
         * @return {void}
         */
        enableSubmit(): void {
            this.addressFound = true;
        },

        /**
         * Disable submit button.
         *
         * @return {void}
         */
        disableSubmit(): void {
            this.addressFound = false;
        },

        /**
         * Set address information if address is found
         *
         * @return {void}
         */
        setAddressInfo(value: Dawa): void {
            // this.errors.address = '';
            if (this.addressFound) {
                this.form.address = value.tekst;
                this.zipcode = parseInt(value.data.postnr);
            }
        },

        onSubmit(e: Event): void {
            e.preventDefault();
            this.isLoading = true;

            Api.postLead({
                consentIdGlobal: this.consent?.id ?? '',
                address: this.form.address ?? '',
                firstName: Format.firstName(this.form.name) ?? '',
                lastName: Format.lastName(this.form.name) ?? '',
                phoneNumber: this.form.phone ?? '',
                email: this.form.mail ?? '',
                responsibleShopNo: this.brokerId,
                type: LeadTypes.SalesValuationShop.toString(),
                httpReferral: this.referrer,
                utmCampaign: this.utmCampaign,
                utmContent: this.utmContent,
                utmMedium: this.utmMedium,
                utmSource: this.utmSource,
            })
                .then(() => {
                    this.isLoading = false;
                    this.incrementStep();

                    tracking.trackRawEvent({
                        event: 'trackForm',
                        eventData: {
                            category: 'Formular',
                            action: 'Salgsvurdering gennemfoert',
                            label: 'Salgsvurdering',
                            formularIndgang: 'Aaben formular',
                            formularStepnavn: 'Kvittering',
                            maeglerId: this.brokerId ?? '',
                        },
                    });
                })
                .catch((error: any): void => {
                    this.errors = error.errors ?? [];
                    this.isLoading = false;
                })
                .finally(() => {
                    this.isLoading = false;
                    const brokerSalesValuation = this.$refs
                        .brokerSalesValuation as HTMLDivElement;
                    brokerSalesValuation?.scrollIntoView();
                });
        },
    },
};
