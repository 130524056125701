import { BOUNDS, LATITUDE, LONGITUDE, ZOOM } from '@/constants/search.const';
import State from './interface';

export default <State>{
    activeProperty: null,
    area: [],
    bounds: BOUNDS,
    filters: [],
    filtersLoaded: false,
    fitProperties: true,
    initialLoad: true,
    isDrawingArea: false,
    latitude: LATITUDE,
    listIsLoading: false,
    listResults: [],
    longitude: LONGITUDE,
    mapHasInteraction: false,
    mapIsLoading: false,
    mapResults: [],
    order: 'relevant',
    page: 1,
    query: '',
    queryInput: '',
    redirect: false,
    totalCount: 0,
    userLocation: null,
    view: '',
    zoom: ZOOM,
    propertiesListCancelToken: undefined,
    propertiesMapCancelToken: undefined,
    listIsCanceled: false,
};
