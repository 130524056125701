import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["srcset", "src", "alt"]
const _hoisted_2 = ["loop", "autoplay", "muted", "controls", "src", "poster"]
const _hoisted_3 = ["loop", "autoplay", "muted", "controls", "src", "poster"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_youtube = _resolveComponent("m-youtube")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("img", {
      class: "o-homeEstimateLandingHero__image",
      srcset: $options.currentImageSrcset,
      src: $options.currentImageUrl,
      alt: $options.currentImageAltText,
      sizes: "100vw",
      height: "900",
      width: "1600"
    }, null, 8 /* PROPS */, _hoisted_1),
    (
            !$data.isDesktop &&
            $props.content.mobileVideo &&
            $props.content.mobileVideo.videoType === 'youtube' &&
            $props.content.mobileVideo.videoId
        )
      ? (_openBlock(), _createBlock(_component_m_youtube, {
          key: 0,
          class: "o-homeEstimateLandingHero__video",
          "youtube-id": $props.content.mobileVideo.videoId,
          "video-title": ""
        }, null, 8 /* PROPS */, ["youtube-id"]))
      : _createCommentVNode("v-if", true),
    (
            $data.isDesktop &&
            $props.content.desktopVideo &&
            $props.content.desktopVideo.videoType === 'youtube' &&
            $props.content.desktopVideo.videoId
        )
      ? (_openBlock(), _createBlock(_component_m_youtube, {
          key: 1,
          class: "o-homeEstimateLandingHero__video",
          "youtube-id": $props.content.desktopVideo.videoId,
          "video-title": ""
        }, null, 8 /* PROPS */, ["youtube-id"]))
      : _createCommentVNode("v-if", true),
    (
            !$data.isDesktop &&
            $props.content.mobileVideo &&
            $props.content.mobileVideo.videoType === 'cms' &&
            $props.content.mobileVideo.videoUrl
        )
      ? (_openBlock(), _createElementBlock("video", {
          key: 2,
          class: "o-homeEstimateLandingHero__video",
          loop: $props.content.enableVideoLoop,
          autoplay: $props.content.disableVideoControls,
          muted: $props.content.disableVideoControls,
          controls: !$props.content.disableVideoControls,
          src: $props.content.mobileVideo.videoUrl,
          poster: $options.currentImageUrl,
          disablePictureInPicture: "",
          controlsList: "nodownload",
          playsinline: ""
        }, null, 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    (
            $data.isDesktop &&
            $props.content.desktopVideo &&
            $props.content.desktopVideo.videoType === 'cms' &&
            $props.content.desktopVideo.videoUrl
        )
      ? (_openBlock(), _createElementBlock("video", {
          key: 3,
          class: "o-homeEstimateLandingHero__video",
          loop: $props.content.enableVideoLoop,
          autoplay: $props.content.disableVideoControls,
          muted: $props.content.disableVideoControls,
          controls: !$props.content.disableVideoControls,
          src: $props.content.desktopVideo.videoUrl,
          poster: $options.currentImageUrl,
          disablePictureInPicture: "",
          controlsList: "nodownload",
          playsinline: ""
        }, null, 8 /* PROPS */, _hoisted_3))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}