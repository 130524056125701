
import { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { EventBus } from '@/functions/eventBus';
import mRadio from '@/components/molecules/form/Radio.vue';
import tracking from '@/functions/tracking';

export interface Texts {
    drawnArea: string;
    mapBounds: string;
    placeholder: string;
    placeholderMobile: string;
    placeholderBroker: string;
    placeholderBrokerMobile: string;
    save: string;
    searchButton: string;
    suggestion: string;
}

interface SearchPage {
    name: string;
    url: string;
}

interface Data {
    isActive: boolean;
    isTypeActive: boolean;
    isFocused: boolean;
    overwriteActive: boolean;
    submitButtonIsFocused: boolean;
    value: string;
    radio: string;
    isDesktop: boolean;
}

export default {
    components: {
        mRadio,
    },

    props: {
        isPropertySearchPage: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        isBrokerSearchPage: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        searchUrl: {
            type: String as PropType<string>,
            default: () => '',
        },
        currentUrl: {
            type: String as PropType<string>,
            default: () => '',
        },
        searchPages: {
            type: Array as PropType<SearchPage[]>,
            default: () => [],
        },
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                drawnArea: '',
                mapBounds: '',
                placeholder: '',
                placeholderMobile: '',
                placeholderBroker: '',
                placeholderBrokerMobile: '',
                save: '',
                searchButton: '',
                suggestion: '',
            }),
        },
    },

    data(): Data {
        return {
            isActive: false,
            isTypeActive: false,
            isFocused: false,
            overwriteActive: false,
            submitButtonIsFocused: false,
            value: '', // by design this value is not modelled by the field as it is not supposed to maintain a value in order to display the placeholder
            radio:
                this.isPropertySearchPage || this.isBrokerSearchPage
                    ? this.currentUrl ?? this.searchPages[0].url
                    : '',
            isDesktop: EventBus.isDesktop,
        };
    },

    computed: {
        ...mapGetters({
            area: 'search/area',
            filters: 'search/filters',
            mapHasInteraction: 'search/mapHasInteraction',
            query: 'search/query',
            queryInput: 'search/queryInput',
            brokerQuery: 'brokerSearch/query',
        }),

        /**
         * Check if search page.
         *
         * @return {SearchPage}
         */
        isSearchPage(): boolean {
            return this.isPropertySearchPage || this.isBrokerSearchPage;
        },

        /**
         * Get the current search page.
         *
         * @return {SearchPage}
         */
        currentSeachPage(): SearchPage | undefined {
            return this.searchPages.find(
                (sp: SearchPage) => sp.url === this.currentUrl,
            );
        },

        /**
         * Check if the store has any filters.
         *
         * @return {boolean}
         */
        hasFilters(): boolean {
            return this.filters.length > 0;
        },

        /**
         * Check if there are any active tags.
         *
         * @return {boolean}
         */
        hasTags(): boolean {
            return (
                (this.area && this.area.length > 0) ||
                this.query !== '' ||
                this.brokerQuery !== '' ||
                this.mapHasInteraction
            );
        },

        /**
         * Get the placeholder to be shown in input field.
         *
         * @return {string}
         */
        placeholder(): string {
            let string = this.isDesktop
                ? this.texts.placeholder
                : this.texts.placeholderMobile;

            if (this.isPropertySearchPage) {
                string = this.isDesktop
                    ? this.texts.placeholder
                    : this.texts.placeholderMobile;
            }

            if (this.isBrokerSearchPage) {
                string = this.isDesktop
                    ? this.texts.placeholderBroker
                    : this.texts.placeholderBrokerMobile;
            }

            if (this.isFocused) {
                return string;
            }

            if (this.mapHasInteraction) {
                string = this.texts.mapBounds;
            }

            if (this.query !== '') {
                string = this.query;

                if (this.mapHasInteraction) {
                    string += ` + ${this.texts.mapBounds}`;
                }
            }

            if (this.brokerQuery !== '') {
                string = this.brokerQuery;

                if (this.mapHasInteraction) {
                    string += ` + ${this.texts.mapBounds}`;
                }
            }
            
            if(this.queryInput !== '' && this.submitButtonIsFocused) {
                // if the submit button is focused, the placeholder should be the current input value
                string = this.queryInput;
            }

            if (
                this.isPropertySearchPage &&
                this.area &&
                this.area.length > 0
            ) {
                string = this.texts.drawnArea;
            }

            return string;
        },

        /**
         * Check if filters should be visible.
         *
         * @return {boolean}
         */
        showFilters(): boolean {
            if (this.isSearchPage) {
                if (this.currentSeachPage?.url.includes('ejendomsmaegler')) {
                    return false;
                }
                return true;
            }
            return false;
        },
    },
    mounted() {
        EventBus.$on('app.resize', () => {
            this.isDesktop = EventBus.isDesktop;
        });
    },

    watch: {
        radio(value: string) {
            window.location.href = `${value}`;
        },
    },

    methods: {
        ...mapActions({
            openModal: 'modal/open',
            setArea: 'search/setArea',
            setMapHasInteraction: 'search/setMapHasInteraction',
            setBrokerQuery: 'brokerSearch/setQuery',
            setPropertyQuery: 'search/setQuery',
            setQueryInput: 'search/setQueryInput',
            setView: 'search/setView',
        }),

        /**
         * Handle "after-enter" lifecycle hook of transition.
         *
         * @return {void}
         */
        onAfterEnter(): void {
            if (this.$refs.searchInput !== undefined) {
                (this.$refs.searchInput as HTMLInputElement)?.focus();

                EventBus.$on('app.click', () => {
                    this.isActive = false;
                    this.isFocused = false;
                });
            }
        },

        /**
         * Handle "before-leave" lifecycle hook of transition.
         *
         * @return {void}
         */
        onBeforeLeave(): void {
            EventBus.$off('app.click');
        },

        /**
         * Handle blur event on input field.
         *
         * @return {void}
         */
        onBlur(): void {
            setTimeout(() => {
                this.isFocused = false;
            }, 100);
        },

         /**
         * Handle blur event on submit button.
         *
         * @return {void}
         */
         onSubmitButtonBlur(): void {
            this.submitButtonIsFocused = false;
        },

        /**
         * Handle click event on buttons.
         *
         * @param {string} value
         * @return {void}
         */
        onClick(value: string): void {
            if (value === 'open') {
                this.onFocus();
            } else if (value === 'close') {
                this.isActive = false;
                this.isFocused = false;
                this.overwriteActive = true;
            } else if (value === 'filters') {
                this.openModal('filters');
            } else if (value === 'suggestion') {
                this.setView('map');
                tracking.track(
                    'trackBoligsoegning',
                    'Boligsoegning',
                    'Udforsk kort',
                    window.location.href,
                );
            } else if (value === 'save') {
                // TODO: Handle saving current search filters
            } else if (value === 'removeQuery') {
                this.setArea([]);
                this.setQueryInput('');
                this.value = '';
                this.onSubmit();
            } else if (value === 'searchType') {
                this.isTypeActive = !this.isTypeActive;
            }
        },

        /**
         * Handle focus event on input field.
         *
         * @return {void}
         */
        onFocus(): void {
            this.isActive = true;
            this.isFocused = true;
            this.overwriteActive = false;
        },

        /**
         * Handle focus event on submit button.
         *
         * @return {void}
         */
         onSubmitButtonFocus(): void {
            this.submitButtonIsFocused = true;
        },

        /**
         *  Handle submit event on input field.
         *
         * @param {string} value
         * @return {void}
         */
        onSubmit(): void {
            this.setArea([]);
            const value = this.queryInput.trim();
            this.setMapHasInteraction(false);
            (this.$refs.searchInput as HTMLInputElement).blur();

            // All of this MUST be wrapped in a setTimeout, otherwise the search will not work on Android devices
            setTimeout(() => {
                this.isFocused = false;

                if (this.isSearchPage) {
                    if (this.isPropertySearchPage) {
                        tracking.track(
                            'trackBoligsoegning',
                            'Boligsoegning',
                            value,
                            window.location.href,
                        );
                        this.setPropertyQuery(value);
                        EventBus.$emit('search.query');
                    } else {
                        this.setBrokerQuery(value);
                        if (value.includes('filialer:')) {
                            tracking.track(
                                'trackMaeglersoegning',
                                'Maeglersoegning',
                                value,
                                window.location.href,
                            );
                            EventBus.$emit('brokerSearch.relatedQuery');
                        } else if (value !== '') {
                            tracking.track(
                                'trackMaeglersoegning',
                                'Maeglersoegning',
                                value,
                                window.location.href,
                            );
                            EventBus.$emit('brokerSearch.query');
                        } else {
                            tracking.track(
                                'trackMaeglersoegning',
                                'Maeglersoegning',
                                'reset',
                                window.location.href,
                            );
                            EventBus.$emit('brokerSearch.reset');
                        }
                    }
                } else {
                    let query = `/${this.searchUrl}?q=${value}`;
                    tracking.track(
                        'trackBoligsoegning',
                        'Boligsoegning',
                        value,
                        window.location.href,
                    );
                    window.location.href = query;
                }
            }, 100);
        },

        onInput(event: Event) {
            // we need to set the inputted value outside of the compoenent to prevent rerendering and thereby resetting the input field value to the initial value
            this.setQueryInput((event.target as HTMLInputElement).value); 
        },
    },
};
