
import { mapActions, mapGetters } from 'vuex';
import { PropType } from 'vue';
import Api from '@/functions/api';
import { EventBus } from '@/functions/eventBus';
import tracking from '@/functions/tracking';
import Dawa from '@/interfaces/dawa.interface';

interface Content {
    helpText: string;
    buttonText: string;
    buttonUrl: string;
    placeholder: string;
    ariaLabel: string;
    errorMessage: string;
}

interface Data {
    addressFound: boolean;
    address: string;
    dawa: Dawa | undefined;
    error: boolean;
    focusHasBeenTracked: boolean;
    isFocused: boolean;
    isLoading: boolean;
}

export default {
    props: {
        content: {
            type: Object as PropType<Content>,
            default: () => ({
                helpText: '',
                buttonText: '',
                buttonUrl: '',
                placeholder: '',
                ariaLabel: '',
                errorMessage: '',
            }),
        },
    },

    data(): Data {
        return {
            addressFound: false,
            address: '',
            dawa: undefined,
            error: false,
            focusHasBeenTracked: false,
            isFocused: false,
            isLoading: false,
        };
    },

    computed: {
        ...mapGetters({
            show: 'modal/show',
            type: 'modal/type',
        }),

        /**
         * Check if estimate teaser modal is to be shown.
         *
         * @return {boolean}
         */
        showEstimateTeaserInfo(): boolean {
            return this.show && this.type === 'estimateLandingPageTeaser';
        },
    },

    watch: {
        isFocused: {
            handler(newValue) {
                if (newValue && !this.focusHasBeenTracked) {
                    this.focusHasBeenTracked = true;
                    tracking.trackRawEvent({
                        event: 'trackForm',
                        eventData: {
                            category: 'Formular',
                            action: 'Boligskoen initieret',
                            label: 'Boligskoen',
                        },
                    });
                }
            },
            immediate: false,
        },
    },

    mounted() {
        EventBus.$on('app.keyup', (keycode: string) => {
            if (keycode === 'Enter' && this.addressFound && this.isFocused) {
                this.onSubmit();
            }
        });

        const sessionData = sessionStorage.getItem(
            'houseEstimateData',
        ) as string;

        if (sessionData) {
            this.setDawaInformation(JSON.parse(sessionData));
        }
    },

    unmounted() {
        EventBus.$off('app.keyup');
    },

    methods: {
        ...mapActions({
            openModal: 'modal/open',
            setDawaInformation: 'homeEstimate/setDawaInformation',
        }),

        /**
         * Set whether input field is focused
         *
         * @return {void}
         */
        setIsFocused(value: boolean): void {
            this.isFocused = value;
        },

        /**
         * Enable submit button.
         *
         * @return {void}
         */
        enableSubmit(): void {
            this.addressFound = true;
        },

        /**
         * Disable submit button.
         *
         * @return {void}
         */
        disableSubmit(): void {
            this.addressFound = false;
        },

        /**
         * Set address information if address is found
         *
         * @return {void}
         */
        setAddressInfo(value: Dawa): void {
            this.error = false;
            if (this.addressFound) {
                this.dawa = value;
                this.address = value.tekst;
            }
        },

        /**
         * Open modal
         *
         * @return {void}
         */
        onClickEstimateTeaserInfo(): void {
            this.openModal('estimateLandingPageTeaser');
            tracking.trackRawEvent({
                event: 'trackBoligskoen',
                eventData: {
                    category: 'Boligskoen',
                    action: 'Klik paa info ikon',
                    label: 'Tjek din boligs værdi',
                    formularStepnavn: 'Landing page',
                },
            });
        },

        /**
         * Handle submit event.
         *
         * @return {void}
         */
        onSubmit(): void {
            if (this.dawa) {
                this.isLoading = true;
                this.setDawaInformation(this.dawa);

                sessionStorage.setItem(
                    'houseEstimateData',
                    JSON.stringify(this.dawa),
                );

                const id = this.dawa.data.id;

                Api.hasPropertyEstimate({
                    addressId: id,
                })
                    .then((response: any) => {
                        if (response.exists === true) {
                            tracking.trackRawEvent({
                                event: 'trackForm',
                                eventData: {
                                    category: 'Formular',
                                    action: 'Boligskoen indgang gennemfoert',
                                    label: 'Boligskoen',
                                },
                            });

                            sessionStorage.setItem(
                                'houseEstimateSliderReset',
                                'true',
                            );

                            const text: string = '';
                            const hash: string = '';

                            sessionStorage.setItem(
                                'houseEstimateSegmentationData',
                                JSON.stringify({
                                    text,
                                    hash,
                                }),
                            );

                            this.$store.dispatch('homeEstimate/open');

                            setTimeout(() => {
                                this.isLoading = false;
                                this.address = '';
                            }, 500);
                        } else {
                            this.error = true;
                            this.isLoading = false;
                            tracking.trackRawEvent({
                                event: 'trackForm',
                                eventData: {
                                    category: 'Formular',
                                    action: 'Boligskoen indgang ikke gennemfoert',
                                    label: 'Boligskoen',
                                },
                            });
                        }
                    })
                    .catch(() => {
                        this.error = true;
                        this.isLoading = false;
                    });
            }
        },
    },
};
