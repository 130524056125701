
import { PropType } from 'vue';
import mMap from '../../molecules/Map.vue';
import { mapGetters, mapActions } from 'vuex';
import {
    BOUNDS,
    LATITUDE,
    LONGITUDE,
    MAX_BOUNDS,
    ZOOM,
} from '../../../constants/search.const';
import Api from '../../../functions/api';
import MapCluster from '../../../interfaces/mapCluster.interface';
import MapDot from '../../../interfaces/mapDot.interface';
import MapFavorite from '../../../interfaces/mapFavorite.interface';
import MapNeighborhood from '../../../interfaces/mapNeighborhood.interface';
import MapProperty from '../../../interfaces/mapProperty.interface';
import FavoritesMapResponse from '../../../interfaces/responses/favoritesMapResponse.interface';
import NeighborhoodsMapResponse from '../../../interfaces/responses/neighborhoodsMapResponse.interface';
import PropertiesMapResponse from '../../../interfaces/responses/propertiesMapResponse.interface';
import BrokersMapResponse from '../../../interfaces/responses/brokersMapResponse.interface';
import MapBroker from '../../../interfaces/mapBroker.interface';
import { EventBus } from '@/functions/eventBus';
import POI from '@/interfaces/POI.interface';

interface Data {
    bounds: number[][];
    brokers: MapBroker[];
    favorites: MapFavorite[];
    isLoading: boolean;
    latitude: number;
    longitude: number;
    maxBounds: { lat: number; lng: number }[];
    neighborhoods: (MapCluster | MapNeighborhood)[];
    properties: (MapCluster | MapDot | MapProperty)[];
    zoom: number;
    pois: POI[];
}

export interface Texts {
    openHouseTeaser: string;
    openHouseTeaserSignup: string;
}

export default {
    components: {
        mMap,
    },

    props: {
        municipalityId: {
            type: Number as PropType<number>,
            required: false,
        },
        neighborhoodId: {
            type: Number as PropType<number>,
            required: false,
        },
        polygon: {
            type: Array as PropType<number[][]>,
            default: () => [],
        },
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                openHouseTeaser: '',
                openHouseTeaserSignup: '',
            }),
        },
        isComingSoon: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
    },

    data(): Data {
        return {
            bounds: BOUNDS,
            brokers: [],
            favorites: [],
            isLoading: true,
            latitude: LATITUDE,
            longitude: LONGITUDE,
            maxBounds: MAX_BOUNDS,
            neighborhoods: [],
            properties: [],
            zoom: EventBus.isDesktop ? ZOOM : ZOOM - 1,
            pois: [],
        };
    },

    computed: {
        ...mapGetters({
            currentPoi: 'pointsOfInterest/currentPoi',
            currentRoute: 'pointsOfInterest/currentRoute',
            showFavorites: 'pointsOfInterest/showFavorites',
            selectedPois: 'pointsOfInterest/selectedPois',
        }),

        chosenPOITypes(): string[][] | [] {
            if (
                this.currentPoi &&
                !this.selectedPois.some(
                    (selectedPoi) =>
                        selectedPoi.key === this.currentPoi?.typeKey,
                )
            ) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.setCurrentRoutes([]);
                this.setCurrentPoi(undefined);
            }
            if (!this.selectedPois.length) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.pois = [];
                this.setCurrentRoutes([]);
                return [];
            }

            return this.selectedPois.map(
                (selectedPoi: {
                    key: string;
                    value: { key: string; title: string }[];
                }) => {
                    return selectedPoi.value.map((value) => value.key);
                },
            );
        },

        minZoomForEnablingPOIs(): number {
            return this.isDesktop ? 14 : 11;
        },
    },

    watch: {
        chosenPOITypes(): void {
            this.getPOIs();
        },
        zoom: {
            handler(): void {
                this.setSelectorsEnabled(
                    this.zoom >= this.minZoomForEnablingPOIs,
                );
            },
            immediate: true,
        },
    },

    methods: {
        ...mapActions({
            setCurrentRoutes: 'pointsOfInterest/setCurrentRoutes',
            setCurrentPoi: 'pointsOfInterest/setCurrentPoi',
            setSelectorsEnabled: 'pointsOfInterest/setSelectorsEnabled',
        }),

        getData(): void {
            this.isLoading = true;
            this.getNeighborhoods();

            if (this.neighborhoodId) {
                const propertiesPromise = this.getProperties();
                const favoritesPromise = this.getFavorites();
                const brokersPromise = this.getBrokers();

                Promise.all([
                    propertiesPromise,
                    favoritesPromise,
                    brokersPromise,
                ]).then(() => {
                    this.isLoading = false;
                });
            } else {
                this.isLoading = false;
            }
        },

        getFavorites(): void {
            Api.getFavoritesMap({
                neighborhoodId: this.neighborhoodId,
            }).then((response: FavoritesMapResponse) => {
                this.favorites = response.results;
            });
        },

        getBrokers(): void {
            Api.getBrokers({
                query: '',
            }).then((response: BrokersMapResponse) => {
                this.brokers = response.results;
            });
        },

        getNeighborhoods(): Promise<void> {
            return Api.getNeighborhoodsMap({
                bounds: this.bounds,
                municipalityId: this.municipalityId ?? undefined,
                neighborhoodId: this.neighborhoodId ?? undefined,
                zoom: this.zoom,
            }).then((response: NeighborhoodsMapResponse) => {
                this.neighborhoods = response.results;
            });
        },

        getProperties(): void {
            if (this.isComingSoon) {
                this.properties = [];
                return;
            }

            Api.getPropertiesMap({
                bounds: this.bounds,
                neighborhoodId: this.neighborhoodId,
                zoom: this.zoom,
            }).then((response: PropertiesMapResponse) => {
                this.properties = response.results;
            });
        },

        /**
         * Fetch POIs from ViaMap to render on map
         * Since we are now using the same map component for the neighborhood map
         * and also using it as a sort of propertymap on the "Coming Soon" page,
         * we need to check if the map is "coming soon" or not
         *
         * @return {void}
         */
        getPOIs(): void {
            // Make sure to only fetch POIs if the map is used on the "coming soon" page
            if (!this.isComingSoon) {
                this.pois = [];
                return;
            }

            if (this.zoom < this.minZoomForEnablingPOIs) {
                if (this.currentPoi) {
                    this.pois = [this.currentPoi];
                } else {
                    this.pois = [];
                }
                return;
            }
            if (this.chosenPOITypes.length) {
                let a = this.bounds.map((item) => {
                    return item[0];
                });
                let b = this.bounds.map((item) => {
                    return item[1];
                });

                let minA = Math.min(...a);
                let maxA = Math.max(...a);

                let minB = Math.min(...b);
                let maxB = Math.max(...b);

                const bBox = `${minB},${minA}+${maxB},${maxA}`;

                Api.getPOIs({
                    bBox,
                    poiTypes: this.chosenPOITypes,
                    lat: this.latitude,
                    lng: this.longitude,
                    zoom: this.zoom,
                }).then((response: any) => {
                    const poisOnly = Object.keys(response.data).map(
                        (poiType): POI[] => {
                            if (response.data[poiType].numfound < 1) {
                                return [];
                            }
                            const groupedType = this.selectedPois.find(
                                (selectedPoi: {
                                    key: string;
                                    value: { key: string; title: string }[];
                                }) => {
                                    return selectedPoi.value.find(
                                        (selectedPoiValues: {
                                            key: string;
                                            title: string;
                                        }) => selectedPoiValues.key === poiType,
                                    );
                                },
                            );
                            const poiTypeDisplayName = groupedType.value.find(
                                (values: { key: string; title: string }) =>
                                    values.key === poiType,
                            );
                            return response.data[poiType].POIs?.map(
                                (poi: POI): POI => {
                                    return {
                                        ...poi,
                                        typeKey: groupedType?.key,
                                        typeName: groupedType?.name,
                                        poiTypeTitle: poiTypeDisplayName?.title,
                                    };
                                },
                            );
                        },
                    );
                    this.pois = poisOnly.flat();
                });
            }
        },

        /**
         * Handle "close" event on map.
         *
         * @return {void}
         */
        onClose(): void {
            this.setCurrentRoutes([]);
        },

        /**
         * Handle "load" event on map.
         *
         * @return {void}
         */
        onLoad(): void {
            this.getData();
        },

        /**
         * Handle "move" event on map.
         *
         * @param {any} event
         * @return {void}
         */
        onMove(payload: any): void {
            this.latitude = payload.latitude;
            this.longitude = payload.longitude;
            this.zoom = payload.zoom;

            const northEast: [number, number] = [
                payload.bounds._ne.lng,
                payload.bounds._ne.lat,
            ];
            const northWest: [number, number] = [
                payload.bounds._sw.lng,
                payload.bounds._ne.lat,
            ];
            const southEast: [number, number] = [
                payload.bounds._ne.lng,
                payload.bounds._sw.lat,
            ];
            const southWest: [number, number] = [
                payload.bounds._sw.lng,
                payload.bounds._sw.lat,
            ];

            this.bounds = [
                southWest,
                southEast,
                northEast,
                northWest,
                southWest,
            ];
            this.getData();
            this.getPOIs();
        },
    },
};
