
import Vue, { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Api from '@/functions/api';
import tracking from '@/functions/tracking';
import FlowData from '@/interfaces/homeEstimateFlow.interface';
import CurrentUser from '@/interfaces/currentUser.interface';
import Broker from '../../../interfaces/broker.interface';
import StatusIndicator from '../../molecules/StatusIndicator.vue';
import HomeEstimateBrokerIntro from './HomeEstimateBrokerIntro.vue';
import Actions from './HomeEstimateFlowActions.vue';
import ParameterExplainer from './HomeEstimateParameterExplainer.vue';
import OrderEstimate from './HomeEstimateOrderEstimate.vue';
import SegmentationStep from '../segmentationStep/SegmentationStep.vue';
import Slider from './HomeEstimateSlider.vue';
import ContactBrokerModal from '../contactBrokerModal/ContactBrokerModal.vue';
import {
    SceneStep2,
    SceneStep3,
    SceneStep4,
    SceneStep5,
    SceneStep6,
} from '@/constants/lottieData';

export interface Data {
    currentDirection: string;
    isLoading: boolean;
    stepsAmount: number;
    activeStep: number;
    showBubble: boolean;
    responsibleBroker: undefined | Broker;
    responsibleBrokerImage: string | undefined;
    responsibleBrokerId: string | undefined;
    ratingSteps: string[];
    selectedSegment: string;
    ratings: {
        amenity: number;
        energy: number;
        exterior: number;
        interior: number;
        location: number;
    };
    segment: string;
    segmentHash: string;
    brokerData: object;
    sliderSavedValues: number[];
}

interface SliderChangePayload {
    val: number;
    trueVal: number;
    slider: any;
    justInitialized: boolean;
}

export interface Configuration {
    resultPageUrl: string;
}

export interface BrokerPresentation {
    brokerPresentationHeadline: string;
    brokerPresentationText: string;
    brokerPresentationCtaText: string;
    brokerPresentationCtaAltText: string;
}

interface Texts {
    segmentHeadline?: string;
    nextStepCtaText?: string;
    nextStepCtaAltText?: string;
    infoHeadline?: string;
}

export default {
    components: {
        Actions,
        ContactBrokerModal,
        HomeEstimateBrokerIntro,
        OrderEstimate,
        ParameterExplainer,
        SegmentationStep,
        StatusIndicator,
        Slider,
    },

    props: {
        user: {
            type: Object as PropType<CurrentUser>,
            default: () => ({
                name: '',
                email: '',
                phone: '',
            }),
        },
        brokerPresentation: {
            type: Object as PropType<BrokerPresentation>,
            default() {
                return {
                    brokerPresentationHeadline: '',
                    brokerPresentationText: '',
                    brokerPresentationCtaText: '',
                    brokerPresentationCtaAltText: '',
                };
            },
        },
        dataConfiguration: {
            type: Object as PropType<Configuration>,
            default: () => ({
                resultPageUrl: '',
            }),
        },
        dataAmenityRating: {
            type: Object as PropType<FlowData>,
            default: () => ({
                headline: '',
                infoIconScreenReaderText: '',
                infoHeadline: '',
                infoText: '',
                brokerHelpText: '',
                brokerLinkText: '',
                brokerLinkTextLineBreak: false,
                brokerCardText: '',
                ctaText: '',
                ctaAltText: '',
                backCtaText: '',
                backCtaAltText: '',
            }),
        },
        dataEnergyLevelRating: {
            type: Object as PropType<FlowData>,
            default: () => ({
                headline: '',
                infoIconScreenReaderText: '',
                infoHeadline: '',
                infoText: '',
                brokerHelpText: '',
                brokerLinkText: '',
                brokerLinkTextLineBreak: false,
                brokerCardText: '',
                ctaText: '',
                ctaAltText: '',
                backCtaText: '',
                backCtaAltText: '',
            }),
        },
        dataExteriorConditionRating: {
            type: Object as PropType<FlowData>,
            default: () => ({
                headline: '',
                infoIconScreenReaderText: '',
                infoHeadline: '',
                infoText: '',
                brokerHelpText: '',
                brokerLinkText: '',
                brokerLinkTextLineBreak: false,
                brokerCardText: '',
            }),
        },
        dataInteriorConditionRating: {
            type: Object as PropType<FlowData>,
            default: () => ({
                headline: '',
                infoIconScreenReaderText: '',
                infoHeadline: '',
                infoText: '',
                brokerHelpText: '',
                brokerLinkText: '',
                brokerLinkTextLineBreak: false,
                brokerCardText: '',
                ctaText: '',
                ctaAltText: '',
                backCtaText: '',
                backCtaAltText: '',
            }),
        },
        dataLocationRating: {
            type: Object as PropType<FlowData>,
            default: () => ({
                headline: '',
                infoIconScreenReaderText: '',
                infoHeadline: '',
                infoText: '',
                brokerHelpText: '',
                brokerLinkText: '',
                brokerLinkTextLineBreak: false,
                brokerCardText: '',
                ctaText: '',
                ctaAltText: '',
                ctaBackText: 'Tilbage',
                ctaBackAltText: 'Tilbage',
            }),
        },
        dataSegmentation: {
            type: Object as PropType<Texts>,
            default: () => ({
                segmentHeadline: '',
                nextStepCtaText: '',
                nextStepCtaAltText: '',
            }),
        },
        dataContactBrokerModal: {
            type: Object as PropType<string>,
            default: () => ({}),
        },
    },

    data(): Data {
        return {
            currentDirection: '',
            isLoading: false,
            stepsAmount: 7,
            activeStep: 1,
            showBubble: true,
            responsibleBroker: undefined,
            responsibleBrokerImage: '',
            responsibleBrokerId: '',
            ratingSteps: [
                'dataExteriorConditionRating',
                'dataInteriorConditionRating',
                'dataLocationRating',
                'dataEnergyLevelRating',
                'dataAmenityRating',
            ],
            selectedSegment: '',
            ratings: {
                amenity: 0,
                energy: 0,
                exterior: 0,
                interior: 0,
                location: 0,
            },
            segment: '',
            segmentHash: '',
            brokerData: {},
            sliderSavedValues: [50, 50, 50, 50, 50],
        };
    },

    computed: {
        ...mapGetters({
            areaName: 'homeEstimate/areaName',
            modalShow: 'homeEstimate/show',
            modalType: 'homeEstimate/type',
            addressId: 'homeEstimate/addressId',
            zipCode: 'homeEstimate/zipCode',
        }),

        /**
         * Check if the modal should be visible.
         *
         * @return {boolean}
         */
        isVisible(): boolean {
            return this.modalShow;
        },
    },

    watch: {
        activeStep: {
            handler() {
                this.playLottie();
            },
        },
        modalShow: {
            deep: true,
            handler() {
                if (this.isVisible) {
                    this.setUrlStep();
                }
            },
        },
        addressId: {
            handler(newValue) {
                if (newValue) {
                    this.getResponsibleBroker();
                }
            },
            deep: false,
            immediate: false,
        },
    },

    mounted(): void {
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);

        window.addEventListener('popstate', () => {
            url = new URL(window.location.href);
            params = new URLSearchParams(url.search);

            if (params.has('step')) {
                const step: number = parseInt(params.get('step') as string);
                this.activeStep = step;

                this.trackPageView();

                if (this.activeStep === 1) {
                    this.$store.dispatch('homeEstimate/open');
                }
            } else {
                this.$store.dispatch('homeEstimate/close');
            }
        });

        if (!this.addressId) {
            url.searchParams.delete('step');
            window.history.pushState({}, '', url as any);
            return;
        }

        if (params.has('step')) {
            const step: number = parseInt(params.get('step') as string);

            if (step != null) {
                if (step <= this.stepsAmount && step > 0) {
                    this.activeStep = step;
                    this.$store.dispatch('homeEstimate/open', false);
                    this.getResponsibleBroker();
                }
            }
        }

        const sessionSliderData = JSON.parse(
            sessionStorage.getItem('houseEstimateSliderData') as string,
        );

        if (sessionSliderData) {
            this.sliderSavedValues = sessionSliderData;
        }
    },

    methods: {
        ...mapActions({
            openModal: 'modal/open',
        }),

        loadSliderValues(val: number): number {
            const resetSlider = sessionStorage.getItem(
                'houseEstimateSliderReset',
            ) as string;

            if (resetSlider) {
                return 50;
            }

            return this.sliderSavedValues[val];
        },

        /**
         * Tracking for info icon
         *
         * @return {void}
         */
        showEstimateTeaserInfo(stepLabel: string): void {
            this.openModal(`estimateExplainer-${this.activeStep}`);

            tracking.trackRawEvent({
                event: 'trackBoligskoen',
                eventData: {
                    category: 'Boligskoen',
                    action: 'Klik paa info ikon',
                    label: stepLabel,
                    formularStepnavn: stepLabel,
                },
            });
        },

        /**
         * Tracking for status indicator
         *
         * @return {void}
         */
        changeActiveStepFromStatus(e: number): void {
            if (this.activeStep < e) {
                this.currentDirection = 'next';
            } else {
                this.currentDirection = 'prev';
            }

            // Tracking - STEP SHIFT
            tracking.trackRawEvent({
                event: 'trackBoligskoen',
                eventData: {
                    category: 'Boligskoen',
                    action: 'Boligskoen stepskift',
                    label: 'Progressionsbar',
                    formularStepnavn: this.getStepHeadline(),
                },
            });

            this.activeStep = e;
            this.setUrlStep();
            this.trackPageView();

            // Fix for map not resizing properly when going to step 1 via status indicator after reload
            if (this.activeStep === 1) {
                Vue.nextTick(() => {
                    window.dispatchEvent(new Event('resize'));
                });
            }
        },

        /**
         * Set tracking for slider
         *
         * @return {void}
         */
        setHiddenSliderField(obj: SliderChangePayload): void {
            const sliders = obj;

            let sliderParam = [
                'Udvendig stand',
                'Indvendig stand',
                'Beliggenhed',
                'Energiniveau',
                'Herlighedsværdi',
            ];

            if (sliders.slider === 'slider-1') {
                this.ratings.exterior = sliders.val;
                this.sliderSavedValues[0] = sliders.trueVal;
            } else if (sliders.slider === 'slider-2') {
                this.ratings.interior = sliders.val;
                this.sliderSavedValues[1] = sliders.trueVal;
            } else if (sliders.slider === 'slider-3') {
                this.ratings.location = sliders.val;
                this.sliderSavedValues[2] = sliders.trueVal;
            } else if (sliders.slider === 'slider-4') {
                this.ratings.energy = sliders.val;
                this.sliderSavedValues[3] = sliders.trueVal;
            } else if (sliders.slider === 'slider-5') {
                this.ratings.amenity = sliders.val;
                this.sliderSavedValues[4] = sliders.trueVal;
            }

            sessionStorage.setItem(
                'houseEstimateSliderData',
                JSON.stringify(this.sliderSavedValues),
            );

            sessionStorage.removeItem('houseEstimateSliderReset');

            if (!obj.justInitialized) {
                const lb = sliderParam[this.activeStep - 2];

                if (lb !== undefined) {
                    tracking.trackRawEvent({
                        event: 'trackBoligskoen',
                        eventData: {
                            category: 'Boligskoen',
                            action: 'Slider',
                            label: lb,
                            formularStepnavn: this.getStepHeadline(),
                        },
                    });
                }
            }
        },

        /**
         * Set URL on step change
         *
         * @return {void}
         */
        setUrlStep(): void {
            let url = new URL(window.location.href);
            url.searchParams.set('step', `${this.activeStep}`);
            window.history.pushState({}, '', url as any);
        },

        /**
         * Controls flow navigation on CTA
         *
         * @return {void}
         */
        flowNavigation(dir: string): void {
            if (dir === 'prev') {
                tracking.trackRawEvent({
                    event: 'trackBoligskoen',
                    eventData: {
                        category: 'Boligskoen',
                        action: 'Boligskoen stepskift',
                        label: 'Tilbage',
                        formularStepnavn: this.getStepHeadline(),
                    },
                });

                this.currentDirection = 'prev';
                this.activeStep -= 1;
            } else if (dir === 'next') {
                tracking.trackRawEvent({
                    event: 'trackBoligskoen',
                    eventData: {
                        category: 'Boligskoen',
                        action: 'Boligskoen stepskift',
                        label: 'Næste',
                        formularStepnavn: this.getStepHeadline(),
                    },
                });

                this.currentDirection = 'next';
                if (this.activeStep < this.stepsAmount) {
                    this.activeStep += 1;
                }
            }

            this.setUrlStep();
            this.trackPageView();
        },

        submitForm(): void {
            tracking.trackRawEvent({
                event: 'trackBoligskoen',
                eventData: {
                    category: 'Boligskoen',
                    action: 'Boligskoen segmentering',
                    label: this.segment,
                },
            });

            tracking.trackRawEvent({
                event: 'trackBoligskoen',
                eventData: {
                    category: 'Boligskoen',
                    action: 'Boligskoen se resultat',
                },
            });
        },

        /**
         * Set segment data for backend
         *
         * @return {void}
         */
        setSelectedSegment(e: any): void {
            this.segment = e.text;
            this.segmentHash = e.hash;
        },

        /**
         * Returns headline for tracking
         *
         * @return {string}
         */
        getStepHeadline(): string {
            let stepName: string = '';

            if (this.activeStep > 1 && this.activeStep < 7) {
                stepName =
                    this.$props[this.ratingSteps[this.activeStep - 2]].headline;
            } else if (this.activeStep === 1) {
                stepName =
                    this.$props.brokerPresentation.brokerPresentationHeadline.replace(
                        '#bynavn#',
                        this.areaName,
                    );
            } else if (this.activeStep === 7) {
                stepName = this.$props.dataSegmentation.segmentHeadline;
            }

            return stepName;
        },

        /**
         * Logo tracking
         *
         * @return {void}
         */
        clickLogo(): void {
            tracking.trackRawEvent({
                event: 'Navigation',
                eventData: {
                    category: 'Boligskoen',
                    action: 'Navigation header klik',
                    label: 'Logo',
                    formularStepnavn: this.getStepHeadline(),
                },
            });
        },

        /**
         * Track page view on reload
         *
         * @return {void}
         */
        onAfterEnterOverlayTransition(): void {
            document.body.classList.remove('no-flow-animation');
            this.trackPageView();
        },

        /**
         * Track page view
         *
         * @return {void}
         */
        trackPageView(): void {
            tracking.trackRawEvent({
                event: 'trackBoligskoen',
                eventData: {
                    category: 'Boligskoen',
                    action: 'Boligskoen step views',
                    label: 'blank',
                    formularStepnavn: this.getStepHeadline(),
                },
            });
        },

        async getResponsibleBroker() {
            let response = await Api.getResponsibleBroker({
                addressId: this.addressId,
                zipCode: this.zipCode,
            });
            this.responsibleBroker = {
                ...response.data,
                email: undefined,
                phone: undefined,
            };
            this.brokerData = {
                ...this.$props.dataContactBrokerModal,
                brokerEmployee: response.data,
            };

            this.responsibleBrokerImage = response.data?.imageUrl;
            this.responsibleBrokerId = response.data?.brokerId;
        },

        playLottie(): void {
            Vue.nextTick(() => {
                const lottieElement = document.getElementById(
                    `lottie${this.activeStep}`,
                ) as HTMLDivElement;

                if (!lottieElement) return;

                window['lottie']?.destroy();

                const scenes = [
                    SceneStep2,
                    SceneStep3,
                    SceneStep4,
                    SceneStep5,
                    SceneStep6,
                ];

                let lottieParams = {
                    container: lottieElement,
                    renderer: 'svg',
                    loop: false,
                    autoplay: true,
                    animationData: scenes[this.activeStep - 2],
                };

                let anim;
                anim = window['lottie']?.loadAnimation(lottieParams);
            });
        },
    },
};
