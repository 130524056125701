import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  ref: "media"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.$slots.default)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {
          onClickToggle: $options.onClickToggle,
          videoEnded: $options.videoEnded,
          title: $options.title,
          paused: $data.paused,
          autoplay: $props.autoplay
        })
      ], 512 /* NEED_PATCH */))
    : _createCommentVNode("v-if", true)
}