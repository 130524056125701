
import { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import oEmployeeShopCard from '@/components/organisms/broker/EmployeeShopCard.vue';
import oEmployeeShopPanelCard from '@/components/organisms/broker/EmployeeShopPanelCard.vue';
import Employee from '@/interfaces/employee.interface';
import CurrentUser from '@/interfaces/currentUser.interface';
import Api from '@/functions/api';
import Format from '@/functions/format';
import { LeadTypes } from '@/enums/leadtypes.enum';
import LeadConsent from '@/interfaces/leadConsent.interface';
import tracking from '@/functions/tracking';

interface IData {
    currentDirection: string;
    errors: {
        message?: string;
        name?: string;
        mail?: string;
        phone?: string;
    };
    form: {
        message: string;
        name: string;
        mail: string;
        phone: string;
    };
    selectedEmployee: Employee;
    isLoading: boolean;
    isTeamListExpandable: boolean;
}

export interface Texts {
    form: {
        messageLabel: string;
        nameLabel: string;
        mailLabel: string;
        phoneLabel: string;
    };
    contactCtaText: string;
    formHeadline: string;
    formReceiptHeading: string;
    formReceiptBodyText: string;
    formSendButtonText: string;
    formPrivacyLinkText: string;
    formPrivacyLinkUrl: string;
}

export default {
    components: {
        oEmployeeShopCard,
        oEmployeeShopPanelCard,
    },

    props: {
        user: {
            type: Object as PropType<CurrentUser>,
            default: () => ({
                name: '',
                email: '',
                phone: '',
            }),
        },
        employees: {
            type: Array as PropType<Employee[]>,
            default: () => [],
        },
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({}),
        },
        consent: {
            type: Object as PropType<LeadConsent>,
            default: () => ({
                id: '',
                purposeText: '',
            }),
        },
    },

    data(): IData {
        return {
            currentDirection: '',
            errors: {},
            form: {
                message: '',
                name: this.user?.name ?? '',
                mail: this.user?.email ?? '',
                phone: '',
            },
            selectedEmployee: {},
            isLoading: false,
            isTeamListExpandable: false,
        };
    },

    computed: {
        ...mapGetters({
            currentPanel: 'employee/currentPanel',
            currentSubPanel: 'employee/currentSubPanel',
            fixedPanel: 'modal/fixed',
            modalShow: 'modal/show',
            modalType: 'modal/type',
            trackingLabel: 'modal/trackingLabel',
            referrer: 'document/referrer',
            utmSource: 'document/utmSource',
            utmMedium: 'document/utmMedium',
            utmCampaign: 'document/utmCampaign',
            utmContent: 'document/utmContent',
        }),

        /**
         * Check if the modal should be visible.
         *
         * @return {boolean}
         */
        isVisible(): boolean {
            return this.modalShow && this.modalType === 'employee';
        },
    },

    watch: {
        form: {
            deep: true,
            handler() {
                this.errors = {};
            },
        },
    },

    mounted(): void {
        const teamListWrapperElement = this.$refs
            .teamExpandWrapper as HTMLDivElement;
        const teamListElement = this.$refs.teamList as HTMLDivElement;
        if (teamListElement && teamListWrapperElement) {
            if (this.employees.length > 8) {
                if (
                    teamListElement.getBoundingClientRect().height >
                    teamListWrapperElement.getBoundingClientRect().height
                ) {
                    this.isTeamListExpandable = true;
                }
                teamListWrapperElement.classList.add(
                    this.isTeamListExpandable
                        ? 'isExpandable'
                        : 'isNotExpandable',
                );
            } else {
                teamListWrapperElement.classList.add('isNotExpandable');
            }
        }

        const hash = document.location.hash.replace('#', '');
        if (hash) {
            const el = document.querySelector(
                `div[id="${hash}"]`,
            ) as HTMLDivElement;
            if (el) el.scrollIntoView();
        }
    },

    methods: {
        ...mapActions({
            setCurrentPanel: 'employee/setCurrentPanel',
            setCurrentSubPanel: 'employee/setCurrentSubPanel',
            openModal: 'modal/open',
        }),

        /**
         * Go to the next panel/screen.
         *
         * @param {string} value
         * @return {void}
         */
        nextPanel(value: string): void {
            this.currentDirection = 'next';
            this.setCurrentPanel(value);
            tracking.trackRawEvent({
                event: 'trackForm',
                eventData: {
                    category: 'Formular',
                    action: 'Kontakt maegler initieret',
                    label: 'Kontakt maegler',
                    formularIndgang: 'Skriv til mig modul',
                    formularStepnavn: 'Brugerinformation',
                    maeglerId: this.selectedEmployee.brokerId ?? '',
                },
            });
        },

        /**
         * Go to the previous panel/screen.
         *
         * @param {string} value
         * @return {void}
         */
        previousPanel(value: string): void {
            this.currentDirection = 'prev';
            this.setCurrentPanel(value);
        },

        expand(): void {
            const div = this.$refs.teamExpandWrapper as HTMLDivElement;
            div.classList.add('expanded');
        },

        resetModalContent(): void {
            this.form.message = '';
            this.form.name = this.user?.name ?? '';
            this.form.mail = this.user?.email ?? '';
            this.form.phone = '';
            this.currentDirection = '';
            this.setCurrentPanel('');
            this.setCurrentSubPanel(1);
            this.errors.message = '';
            this.errors.name = '';
            this.errors.mail = '';
            this.errors.phone = '';
        },

        onClickChild(payload: any): void {
            if (this.consent?.id) {
                this.resetModalContent();
                const { id, panel, track } = payload;
                this.setCurrentPanel(panel);
                this.openModal('employee');
                this.selectedEmployee = this.employees.find(
                    (employee) => employee.employeeId === id,
                ) as Employee;
                if (track) {
                    tracking.trackRawEvent({
                        event: 'trackForm',
                        eventData: {
                            category: 'Formular',
                            action: 'Kontakt maegler initieret',
                            label: this.trackingLabel ?? 'Kontakt maegler',
                            formularIndgang: 'Skriv til mig modul',
                            formularStepnavn: 'Brugerinformation',
                            maeglerId: this.selectedEmployee.brokerId ?? '',
                        },
                    });
                }
            }
        },

        /**
         * Update value in form.
         *
         * @param {string} key
         * @param {string} value
         * @return {void}
         */
        onChange(key: string, value: string): void {
            // this.$set(this.personObject, 'bio', bio)  // this was needed on vue 2
            // Vue.set(this.form, key, value);

            // this.personObject['bio'] = bio
            this.form[key] = value;
        },

        /**
         * Submit a form.
         *
         * @param {string} value
         * @return {void}
         */
        onSubmit(): void {
            this.isLoading = true;

            Api.postLead({
                consentIdGlobal: this.consent?.id ?? '',
                email: this.form.mail.trim() ?? '',
                firstName: Format.firstName(this.form.name) ?? '',
                lastName: Format.lastName(this.form.name) ?? '',
                message: this.form.message,
                phoneNumber: this.form.phone,
                responsibleShopNo: this.selectedEmployee.brokerId,
                responsibleEmployeeIdGlobal: this.selectedEmployee.employeeId,
                type: LeadTypes.ContactSpecific.toString(),
                httpReferral: this.referrer,
                utmCampaign: this.utmCampaign,
                utmContent: this.utmContent,
                utmMedium: this.utmMedium,
                utmSource: this.utmSource,
            })
                .then(() => {
                    this.currentDirection = 'next';
                    this.setCurrentSubPanel(2);

                    tracking.trackRawEvent({
                        event: 'trackForm',
                        eventData: {
                            category: 'Formular',
                            action: 'Kontakt maegler gennemfoert',
                            label: this.trackingLabel ?? 'Kontakt maegler',
                            formularIndgang: 'Skriv til mig modul',
                            formularStepnavn: 'Kvittering',
                            maeglerId: this.selectedEmployee.brokerId ?? '',
                        },
                    });
                })
                .catch((error: any): void => {
                    this.errors = error.errors ?? [];
                })
                .finally((): void => {
                    this.isLoading = false;
                });
        },
    },
};
