
import { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { LeadTypes } from '@/enums/leadtypes.enum';
import Api from '@/functions/api';
import { EventBus } from '@/functions/eventBus';
import Format from '@/functions/format';
import tracking from '@/functions/tracking';
import Broker from '@/interfaces/broker.interface';
import FormSettings from '@/interfaces/formSettings.interface';
import CurrentUser from '@/interfaces/currentUser.interface';

type FormErrors = {
    name?: string;
    mail?: string;
    phone?: string;
    message?: string;
};

type Consent = {
    purposeText: string;
    id: string;
    heading: string;
    leadType: {
        displayName: string;
    };
};

interface Data {
    isSignupSuccessful: boolean;
    errors: FormErrors;
    isLoading: boolean;
    form: {
        name: string;
        email: string;
        phone: string;
        message?: string;
    };
    brokerId: string;
    isFormInitiated: boolean;
    responsibleBroker: undefined | Broker;
}

export type LatLon = {
    lat: number;
    lon: number;
};

type ContactBrokerModalData = {
    awsAddressGuid: string;
    fullAddress: string;
    latLon: LatLon;
    messageExtension: string;
    brokerEmployee: Broker;
    headline: string;
    text: string;
    ctaText: string;
    ctaAltText: string;
    receiptHeadline: string;
    receiptText: string;
    consent: Consent;
    formSettings: FormSettings;
};

export default {
    props: {
        user: {
            type: Object as PropType<CurrentUser>,
            default: () => ({
                name: '',
                email: '',
                phone: '',
            }),
        },
        curtainData: {
            type: Object as PropType<ContactBrokerModalData>,
            default: () => ({
                awsAddressGuid: '',
                fullAddress: '',
                messageExtension: '',
                brokerEmployee: {
                    address: '',
                    brokerId: '',
                    brokerName: '',
                    city: '',
                    email: '',
                    employeeId: '',
                    imageUrl: '',
                    linkedIn: null,
                    name: '',
                    phone: '',
                    title: '',
                    videoUrl: null,
                    type: '',
                    url: '',
                    zipCode: '',
                },
                latLon: {
                    lat: 0,
                    lon: 0,
                },
                headline: '',
                text: '',
                ctaText: '',
                ctaAltText: '',
                receiptHeadline: '',
                receiptText: '',
                consent: {
                    purposeText: '',
                    id: '',
                    heading: '',
                    leadType: {
                        displayName: '',
                    },
                },
                formSettings: {
                    msgPlaceholder: '',
                    msgErrorMsg: '',
                    namePlaceholder: '',
                    nameErrorMsg: '',
                    addressPlaceholder: '',
                    addressErrorMsg: '',
                    mailPlaceholder: '',
                    mailErrorMsg: '',
                    phonePlaceholder: '',
                    phoneErrorMsg: '',
                    offerPlaceholder: '',
                    offerErrorMsg: '',
                    linkText: '',
                    link: null,
                    submitButtonText: '',
                    brokerErrorMsg: '',
                    heading: '',
                    bodytext: '',
                    commentPlaceholder: '',
                    commentErrorMsg: '',
                    datePlaceholder: '',
                    dateErrorMsg: '',
                    municipalityPlaceholder: '',
                    municipalityErrorMsg: '',
                    desiredAddressPlaceholder: '',
                    consentErrorMsg: '',
                },
            }),
        },
    },

    data(): Data {
        return {
            isSignupSuccessful: false,
            form: {
                name: this.user?.name ?? '',
                email: this.user?.email ?? '',
                phone: '',
                message: '',
            },
            isLoading: false,
            errors: {},
            brokerId: '',
            isFormInitiated: false,
            responsibleBroker: undefined,
        };
    },

    computed: {
        ...mapGetters({
            show: 'modal/show',
            type: 'modal/type',
            areaName: 'homeEstimate/areaName',
            addressId: 'homeEstimate/addressId',
            address: 'homeEstimate/address',
            zipCode: 'homeEstimate/zipCode',
            trackingEntrance: 'modal/trackingEntrance',
            utmSource: 'document/utmSource',
            utmMedium: 'document/utmMedium',
            utmCampaign: 'document/utmCampaign',
            utmContent: 'document/utmContent',
        }),

        showModal(): boolean {
            return this.show && this.type === 'brokerContact';
        },

        isLandingPageEntrance(): boolean {
            return (
                this.trackingEntrance &&
                this.trackingEntrance === 'Boligskoen landing page'
            );
        },
    },

    watch: {
        form: {
            deep: true,
            handler() {
                this.errors = {};
                if (!this.isFormInitiated && this.showModal) {
                    tracking.trackRawEvent({
                        event: 'trackForm',
                        eventData: {
                            category: 'Formular',
                            action: 'Salgsvurdering initieret',
                            label: 'Salgsvurdering',
                            formularIndgang: this.trackingEntrance,
                            formularStepnavn: 'Brugerinformation',
                            maeglerId: this.curtainData.brokerEmployee
                                ? this.curtainData.brokerEmployee.brokerId
                                : this.brokerId,
                        },
                    });
                    this.isFormInitiated = true;
                }
            },
        },
        showModal: {
            handler() {
                this.isFormInitiated = false;
                if (!this.showModal && this.isSignupSuccessful) {
                    this.isSignupSuccessful = false;

                    this.form = {
                        name: this.user?.name ?? '',
                        email: this.user?.email ?? '',
                        phone: '',
                        message: '',
                    };
                }
            },
        },
    },

    mounted() {
        if (window.homeEstimateBaseData) {
            this.brokerId = window.homeEstimateBaseData.brokerId;
        }
        EventBus.$on('app.hashchange', this.onHashChange);
        this.onHashChange();
    },

    methods: {
        ...mapActions({
            openModal: 'modal/open',
            setTrackingEntrance: 'modal/setTrackingEntrance',
        }),

        receiptTextWithAddress() {
            const address = this.curtainData.fullAddress
                ? this.curtainData.fullAddress
                : this.address.properties.label;

            return this.curtainData.receiptText.replace('#adresse#', address);
        },

        onSubmitForm(): void {
            this.isLoading = true;
            const type = LeadTypes.SalesValuationHomeEstimate.toString();
            const excludeValidation = this.isLandingPageEntrance
                ? ['message', 'address']
                : ['message'];
            const address = this.curtainData.fullAddress
                ? this.curtainData.fullAddress
                : this.address.properties.label;
            const message = this.form.message
                ? `${this.form.message}\r\n\r\n${this.curtainData.messageExtension}`
                : this.curtainData.messageExtension;

            Api.postLead(
                {
                    consentIdGlobal: this.curtainData.consent.id,
                    address,
                    email: this.form.email.trim() ?? '',
                    firstName: Format.firstName(this.form.name) ?? '',
                    lastName: Format.lastName(this.form.name) ?? '',
                    message,
                    phoneNumber: this.form.phone ?? '',
                    responsibleShopNo:
                        this.curtainData.brokerEmployee?.brokerId ?? '',
                    responsibleEmployeeIdGlobal: this.isLandingPageEntrance
                        ? ''
                        : this.curtainData.brokerEmployee.employeeId,
                    type,
                    utmCampaign: this.utmCampaign,
                    utmContent: this.utmContent,
                    utmMedium: this.utmMedium,
                    utmSource: this.utmSource,
                },
                excludeValidation,
            )
                .then((): void => {
                    this.isSignupSuccessful = true;

                    tracking.trackRawEvent({
                        event: 'trackForm',
                        eventData: {
                            category: 'Formular',
                            action: 'Salgsvurdering gennemfoert',
                            label: 'Salgsvurdering',
                            formularIndgang: this.trackingEntrance,
                            formularStepnavn: 'Kvittering',
                            maeglerId: this.curtainData.brokerEmployee
                                ? this.curtainData.brokerEmployee.brokerId
                                : this.brokerId,
                        },
                    });
                })
                .catch((error: any): void => {
                    this.errors = error.errors ?? [];
                })
                .finally((): void => {
                    this.isLoading = false;
                });
        },

        onHashChange(): void {
            if (window.location.hash) {
                let hash = window.location.hash.toLowerCase();

                if (hash === '#bestilboligskoen') {
                    this.openModal('brokerContact');
                    this.setTrackingEntrance('Boligskoen CTA modul');
                }
            }
        },
    },
};
