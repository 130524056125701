
import Vue, { PropType } from 'vue';
import { Youtube } from 'vue-youtube';
import { EventBus } from '@/functions/eventBus';
import tracking from '@/functions/tracking';

interface Data {
    pristine: boolean;
}

export default {
    name: 'm-youtube',
    components: {
        Youtube,
    },

    props: {
        videoTitle: {
            type: String as PropType<string>,
            default: () => '',
        },
        youtubeId: {
            type: String as PropType<string>,
            default: () => '',
        },
    },

    data(): Data {
        return {
            pristine: true,
        };
    },

    methods: {
        /**
         * Handle tracking for video
         *
         * @return {void}
         */
        playing(): void {
            if (this.pristine) {
                tracking.click('Video', 'Played', this.videoTitle);
                this.pristine = false;
            }
        },
    },
};
