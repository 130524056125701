
import { mapActions, mapGetters } from 'vuex';
import { PropType } from 'vue';
import Api from '@/functions/api';
import { EventBus } from '@/functions/eventBus';
import tracking from '@/functions/tracking';

type VideoTypes = 'cms' | 'vimeo' | 'youtube' | null;

interface BaseVideo {
    videoType: VideoTypes;
    videoUrl: string;
    videoId?: string | null;
}

interface Content {
    mobileImage: {
        url: string;
        srcset: string;
        altText: string;
    };
    desktopImage: {
        url: string;
        srcset: string;
        altText: string;
    };
    mobileVideo?: BaseVideo;
    desktopVideo?: BaseVideo;
    enableVideoLoop: boolean;
    disableVideoControls: boolean;
}

interface Data {
    backgroundIllustration: string;
    isDesktop: boolean;
}

export default {
    props: {
        content: {
            type: Object as PropType<Content>,
            default: () => ({
                mobileImage: {
                    url: '',
                    srcset: '',
                    altText: '',
                },
                desktopImage: {
                    url: '',
                    srcset: '',
                    altText: '',
                },
                desktopVideo: {
                    videoType: null,
                    videoUrl: '',
                    videoId: '',
                },
                mobileVideo: {
                    videoType: null,
                    videoUrl: '',
                    videoId: '',
                },
                enableVideoLoop: false,
                disableVideoControls: false,
            }),
        },
    },

    data(): Data {
        return {
            backgroundIllustration:
                '../../../../assets/images/boligskoen_hero_illustration.svg',
            isDesktop: EventBus.isDesktop,
        };
    },

    computed: {
        currentImageSrcset(): string {
            if (!this.isDesktop) {
                if (this.content.mobileImage?.srcset) {
                    return this.content.mobileImage?.srcset ?? '';
                }

                return '';
            }

            if (this.isDesktop) {
                if (this.content.desktopImage?.srcset) {
                    return this.content.desktopImage?.srcset ?? '';
                }

                return '';
            }

            return '';
        },

        currentImageUrl(): string {
            if (!this.isDesktop) {
                if (this.content.mobileImage?.url) {
                    return this.content.mobileImage?.url ?? '';
                }

                return '';
            }

            if (this.isDesktop) {
                if (this.content.desktopImage?.url) {
                    return this.content.desktopImage?.url ?? '';
                }

                return '';
            }

            return '';
        },

        currentImageAltText(): string {
            if (!this.isDesktop) {
                if (this.content.mobileImage?.altText) {
                    return this.content.mobileImage?.altText ?? '';
                }

                return '';
            }

            if (this.isDesktop) {
                if (this.content.desktopImage?.altText) {
                    return this.content.desktopImage?.altText ?? '';
                }

                return '';
            }

            return '';
        },

        mobileVideoPoster(): string {
            return this.content.mobileImage?.url ?? '';
        },
    },

    beforeMount(): void {
        EventBus.$on('app.resize', () => {
            this.isDesktop = EventBus.isDesktop;
        });
    },

    beforeUnmount(): void {
        EventBus.$off('app.resize');
    },

    unmounted() {
        EventBus.$off('app.keyup');
    },
};
