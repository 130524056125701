
import Vue, { PropType } from 'vue';
import { EventBus } from '@/functions/eventBus';
import tracking from '@/functions/tracking';

interface Data {
    paused: boolean;
    pristine: boolean;
    videoElement: HTMLVideoElement | null;
    progressElement: HTMLProgressElement | null;
}

export default {
    name: 'm-media',

    props: {
        autoplay: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        controls: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        play: {
            type: String as PropType<string>,
            default: () => '',
        },
        pause: {
            type: String as PropType<string>,
            default: () => '',
        },
        videoTitle: {
            type: String as PropType<string>,
            default: () => '',
        },
        youtubeId: {
            type: String as PropType<string>,
            default: () => '',
        },
    },

    data(): Data {
        return {
            paused: false,
            pristine: true,
            videoElement: null,
            progressElement: null,
        };
    },

    computed: {
        /**
         * Get title to show to screenreaders.
         *
         * @return {string}
         */
        title(): string {
            return !this.paused ? this.pause : this.play;
        },
    },

    mounted(): void {
        this.videoElement = this.$refs.media.querySelector(
            'video',
        ) as HTMLVideoElement;

        this.progressElement = this.$refs.media.querySelector(
            'progress',
        ) as HTMLProgressElement;

        this.paused = !this.autoplay;

        // This check is required for the video in the hero,
        // where we do not want to load it on mobile, only desktop
        if (EventBus.isDesktop && this.videoElement !== null) {
            const source = this.videoElement.querySelector('source');
            if (source !== null && source.hasAttribute('data-src')) {
                source.setAttribute('src', source?.dataset!.src!);
                source.removeAttribute('data-src');
            }
        }

        if (this.videoElement !== null && this.progressElement !== null) {
            this.videoElement.addEventListener('play', this.progressLoop);
        }
    },

    methods: {
        /**
         * Handle tracking for video
         *
         * @return {void}
         */
        trackPlayButton(): void {
            tracking.click('Video', 'Played', this.videoTitle);
        },

        /**
         * Handle click event for for toggling play/pause of video.
         *
         * @return {void}
         */
        onClickToggle(): void {
            this.paused = !this.paused;
            this.paused ? this.videoElement.pause() : this.videoElement.play();

            if (this.pristine) {
                this.trackPlayButton();
                this.pristine = false;
            }
        },

        progressLoop(): void {
            if (this.paused) {
                return;
            }

            this.progressElement.value = Math.round(
                (this.videoElement.currentTime / this.videoElement.duration) *
                    100,
            );
            requestAnimationFrame(this.progressLoop);
        },

        /**
         * Handle event for when the video ends
         *
         * @return {void}
         */
        videoEnded(): void {
            if (this.videoElement !== null) {
                this.videoElement.currentTime = 0;
                this.paused = true;

                // this will make sure to show the poster image after video has ended
                if (!this.videoElement.hasAttribute('loop')) {
                    this.videoElement.autoplay = false;
                    this.videoElement.load();
                }
            }

            if (this.progressElement !== null) {
                this.progressElement.value = 0;
            }
        },
    },
};
