
import Vue, { PropType } from 'vue';
import Api from '@/functions/api';
import Format from '@/functions/format';
import tracking from '@/functions/tracking';
import { EventBus } from '@/functions/eventBus';
import { Breakpoints } from '@/enums/breakpoints.enum';
import GalleryItem from '@/interfaces/galleryItem.interface';
import GalleryItemsResponse from '@/interfaces/responses/galleryItemsResponse.interface';
import mSkeletonLoader from '@/components/molecules/SkeletonLoader.vue';
import mImageGalleryThumbnail from '@/components/molecules/ImageGalleryThumbnail.vue';

interface Texts {
    screenReaderPreviousImage: string;
    screenReaderNextImage: string;
    screenReaderClose: string;
    screenReaderStartGallery: string;
    openFullScreen: string;
    closeFullScreen: string;
}

interface Data {
    currentIndex: number;
    isVisible: boolean;
    galleryItems: GalleryItem[];
    isDesktop: boolean;
    hasTouch: boolean;
    isLoading: boolean;
    galleryTimeout: number | null;
    isFullscreen: boolean;
    trackedGalleryItems: number[];
    trackedVideosPlayedToEndItems: string[];
    trackedVideosStarted: string[];
    shouldTrackItems: boolean;
    dialog: HTMLDialogElement | null;
    useColorLogo: boolean;
}

type GalleryElement = HTMLImageElement | HTMLVideoElement;

export default {
    components: {
        mSkeletonLoader,
        mImageGalleryThumbnail,
    },
    props: {
        propertyId: {
            type: String as PropType<string>,
            default: () => '',
        },
        brokerId: {
            type: String as PropType<string>,
            default: () => '',
        },
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                screenReaderPreviousImage: '',
                screenReaderNextImage: '',
                screenReaderClose: '',
                screenReaderStartGallery: '',
                openFullScreen: '',
                closeFullScreen: '',
            }),
        },
    },

    data(): Data {
        return {
            currentIndex: 0,
            isVisible: false,
            galleryItems: [],
            isDesktop: EventBus.isDesktop,
            hasTouch: EventBus.hasTouch,
            isLoading: false,
            galleryTimeout: null,
            isFullscreen: false,
            trackedGalleryItems: [],
            trackedVideosPlayedToEndItems: [],
            trackedVideosStarted: [],
            shouldTrackItems: false,
            dialog: null,
            useColorLogo: false,
        };
    },

    computed: {
        /**
         * Get the appropriate dimensions for the gallery thumbnails.
         *
         * @return {string[]}
         */
        thumbnailDimensions(): string[] {
            return ['width=208', 'width=416'];
        },
    },

    mounted(): void {
        document.addEventListener('keydown', (e) => {
            if (!this.isVisible) return;
            if (
                e.code === 'Space' ||
                e.code === 'PageDown' ||
                e.code === 'PageUp' ||
                e.code === 'ArrowDown' ||
                e.code === 'ArrowUp' ||
                e.code === 'Home' ||
                e.code === 'End'
            ) {
                this.dialog?.focus();
                return;
            }
        });

        EventBus.$on('app.resize', () => {
            this.isDesktop = EventBus.isDesktop;
        });

        EventBus.$on('app.keyup', (keycode: string) => {
            if (!this.isVisible) return;
            switch (keycode) {
                case 'Escape':
                    this.close();
                    break;
                default:
                    break;
            }
        });
    },

    beforeUnmount(): void {
        EventBus.$off('app.keyup', this.close);
    },

    methods: {
        trackGallery(index: number, mediaType: string): void {
            if (!this.isVisible) return;

            if (this.trackedGalleryItems.includes(index)) return;

            this.trackedGalleryItems.push(index);

            tracking.track(
                'trackBoligpraesentation',
                'Boligpraesentation',
                'Galleri skift',
                this.propertyId,
                {
                    type: mediaType,
                },
            );
        },

        updateColorLogo(): void {
            if (this.isDesktop) return;
            this.useColorLogo = this.dialog?.scrollTop <= 40;
        },

        /**
         * When the video is watched all the way through, track it.
         *
         * @return {void}
         */
        videoEnded(videoId: string): void {
            if (this.trackedVideosPlayedToEndItems.includes(videoId)) return;

            this.trackedVideosPlayedToEndItems.push(videoId);

            tracking.track(
                'trackBoligpraesentation',
                'Boligpraesentation',
                'Video slut',
                this.propertyId,
                {
                    type: 'Galleri',
                    videoId: videoId,
                },
            );
        },

        /**
         * When the video starts playing, pause all other videos in the gallery
         * Also track the first time the video starts
         *
         * @return {void}
         */
        videoPlay(videoId: string, e: Event): void {
            const target = e.target as HTMLVideoElement;

            if (!this.trackedVideosStarted.includes(videoId)) {
                this.trackedVideosStarted.push(videoId);

                tracking.track(
                    'trackBoligpraesentation',
                    'Boligpraesentation',
                    'Video start',
                    this.propertyId,
                    {
                        type: 'Galleri',
                        videoId: videoId,
                    },
                );
            }

            // Select all video elements on the page
            const allVideos = this.dialog?.querySelectorAll(
                'video',
            ) as HTMLVideoElement[];

            // Log or manipulate the non-playing videos as needed
            allVideos.forEach((video) => {
                if (video !== target) {
                    video.pause();
                    video.currentTime = 0;
                }
            });
        },

        /**
         * Toggle the image gallery to and from fullscreen mode
         *
         * @return {void}
         */
        toggleFullScreen(): void {
            const galleryItems = [
                ...document.querySelectorAll(
                    '.o-propertyGallery__galleryImages img, .o-propertyGallery__galleryImages video',
                ),
            ] as GalleryElement[];

            const activeGalleryItem = galleryItems[this.currentIndex];

            this.isFullscreen = !this.isFullscreen;

            Vue.nextTick(() => {
                this.dialog?.scrollTo({
                    top: activeGalleryItem.parentElement.offsetTop,
                    left: 0,
                });

                activeGalleryItem.classList.add('visibleEffect');
            });
        },

        /**
         * Close the gallery.
         *
         * @return {void}
         */
        close(): void {
            document.body.style.overflow = '';
            this.currentIndex = 0;
            this.trackedGalleryItems = [];
            this.isVisible = false;
            this.isLoading = false;
            this.isFullscreen = false;
            this.shouldTrackItems = false;

            Vue.nextTick(() => {
                this.dialog?.close();
            });
        },

        scroll(): void {
            const element = document.querySelector(
                `.o-propertyGallery__galleryImages [data-index="${this.currentIndex}"]`,
            );
            const dialogAfterPseudo =
                window.getComputedStyle(this.dialog, ':after').height || '0px';

            this.dialog?.scrollTo({
                top:
                    element.parentElement.offsetTop -
                    Math.round(parseFloat(dialogAfterPseudo)),
                left: 0,
            });

            (document.activeElement as GalleryElement)?.blur();
            this.dialog?.focus();
        },

        /**
         * Increment index.
         *
         * @return {void}
         */
        next(): void {
            this.shouldTrackItems = true;
            if (this.currentIndex === this.galleryItems.length - 1) return;

            if (this.currentIndex < this.galleryItems.length - 1) {
                this.currentIndex += 1;
            } else {
                this.currentIndex = 0;
            }

            this.scroll();
        },

        /**
         * Handle "click" event on thumbnails.
         *
         * @param {MouseEvent} event
         * @param {number} index
         * @return {void}
         */
        onClick(index: number): void {
            if (this.currentIndex === index) return;
            this.currentIndex = index;

            this.scroll();
        },

        /**
         * Decrease index.
         *
         * @return {void}
         */
        prev(): void {
            this.shouldTrackItems = true;
            if (this.currentIndex === 0) return;

            if (this.currentIndex > 0) {
                this.currentIndex -= 1;
            } else {
                this.currentIndex = this.galleryItems.length - 1;
            }

            this.scroll();
        },

        resetThumbs(): void {
            const allThumbs = document.querySelectorAll(
                '.o-propertyGallery__galleryThumbnailImages button',
            );
            allThumbs.forEach((t) => t.classList.remove('outline'));
        },

        /**
         * Handle when image is intersecting the viewport.
         *
         * @param {entries} IntersectionObserverEntry[]
         * @return {void}
         */
        callbackFunction(entries: IntersectionObserverEntry[]): void {
            // array of observing elements
            entries.forEach((entry: IntersectionObserverEntry) => {
                entry.target.classList.remove('visibleEffect');
                // The logic to apply for each entry

                const rect = entry.boundingClientRect;
                const isPortraitImage = rect.height > rect.width;

                if (
                    (entry.isIntersecting && entry.intersectionRatio >= 0.45) ||
                    (entry.intersectionRatio > 0.2 && isPortraitImage)
                ) {
                    entry.target.classList.add('visibleEffect');

                    const visibleElements = [
                        ...document.querySelectorAll('.visibleEffect'),
                    ] as GalleryElement[];

                    visibleElements.forEach((el) => {
                        if (this.shouldTrackItems) {
                            this.trackGallery(
                                parseInt(el.dataset.index),
                                el.dataset.mediatype,
                            );
                        }
                    });

                    const firstVisibleItem =
                        visibleElements[0] as GalleryElement;
                    const firstVisibleItemIndex = parseInt(
                        firstVisibleItem.dataset.index,
                    );
                    this.currentIndex = firstVisibleItemIndex;
                    let activeThumb = document.querySelector(
                        `button[data-index="${firstVisibleItemIndex}"]`,
                    ) as HTMLButtonElement;

                    this.resetThumbs();

                    activeThumb?.classList.add('outline');

                    document
                        .querySelector(
                            '.o-propertyGallery__galleryThumbnailImages',
                        )
                        .scrollTo({
                            top: activeThumb.offsetTop,
                            left: 0,
                        });

                    activeThumb.focus();
                } else {
                    if (entry.target.tagName === 'VIDEO' && !this.isDesktop) {
                        const video = entry.target as HTMLVideoElement;
                        if (video && !video.paused) {
                            video.pause();
                            video.currentTime = 0;
                        }
                    }

                    if (entry.target.tagName === 'VIDEO' && this.isDesktop) {
                        const video = entry.target as HTMLVideoElement;
                        video.volume = 0;
                        video.pause();
                        video.currentTime = 0;
                    }
                }
            });
        },

        /**
         * Open the gallery on the provided gelleryItem.
         *
         * @param {galleryItemId} string
         * @param {mediaType} string
         * @return {void}
         */
        show(galleryItemId: string, mediaType: string): void {
            document.body.style.overflow = 'hidden';
            this.isVisible = true;

            Vue.nextTick(() => {
                this.dialog = this.$refs.galleryDialog as HTMLDialogElement;

                this.updateColorLogo();
                this.dialog.addEventListener('scroll', () => {
                    this.updateColorLogo();
                });

                this.dialog?.addEventListener(
                    'scrollend',
                    () => {
                        setTimeout(() => {
                            this.shouldTrackItems = true;
                        }, 100);
                    },
                    {
                        passive: true,
                        once: true,
                    },
                );
                this.dialog?.showModal();
            }).then(() => {
                tracking.track(
                    'trackBoligpraesentation',
                    'Boligpraesentation',
                    'Galleri start',
                    this.propertyId,
                    {
                        type: mediaType,
                    },
                );

                Api.getGalleryMedia({
                    propertyId: this.propertyId,
                    brokerId: this.brokerId,
                })
                    .then((response: GalleryItemsResponse) => {
                        this.galleryItems = response.results;

                        const startIndex = this.galleryItems.findIndex(
                            (x: GalleryItem) => x.id === galleryItemId,
                        );
                        this.trackedGalleryItems.push(startIndex);
                        this.currentIndex = startIndex;
                    })
                    .then(() => {
                        var targets = [
                            ...document.querySelectorAll(
                                '.o-propertyGallery__galleryImages img,.o-propertyGallery__galleryImages video',
                            ),
                        ] as GalleryElement[];

                        var options = {
                            //root: document.querySelector(".dialog"),
                            rootMargin: '0px 0px 0px 0px',
                            threshold: [
                                0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9,
                                1,
                            ],
                        };

                        var observer = new IntersectionObserver(
                            this.callbackFunction,
                            options,
                        );

                        targets.forEach((target, index) => {
                            observer.observe(target);
                        });

                        Vue.nextTick(() => {
                            this.currentIndex > 0 &&
                                this.scrollCurrentIntoView();
                        });
                    })
                    .catch(() => {
                        this.isVisible = false;
                    });
            });
        },

        /**
         * Scrolls the current element of the list into view.
         *
         * @return {void}
         */
        scrollCurrentIntoView(): void {
            const el = document.querySelector(
                `.o-propertyGallery__galleryImages [data-index="${this.currentIndex}"]`,
            );

            const extraOffset =
                window.getComputedStyle(this.dialog, ':after').height || '0px';

            if (el) {
                this.dialog?.scrollTo({
                    top:
                        el.parentElement.offsetTop -
                        Math.round(parseFloat(extraOffset)),
                    left: 0,
                });
            }
        },
    },
};
