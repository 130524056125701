import axios, { AxiosError, AxiosResponse, CancelTokenSource } from 'axios';
import ArticleViewCountResponse from '@/interfaces/responses/articleViewCountResponse.interface';
import BrokersMapResponse from '@/interfaces/responses/brokersMapResponse.interface';
import EstimateRequestResponse from '@/interfaces/responses/estimateRequestResponse.interface';
import FavoritesMapResponse from '@/interfaces/responses/favoritesMapResponse.interface';
import NeighborhoodsMapResponse from '@/interfaces/responses/neighborhoodsMapResponse.interface';
import NeighborhoodLocalVotesResponse from '@/interfaces/responses/neighborhoodLocalVotesResponse.interface';
import PropertiesListResponse from '@/interfaces/responses/propertiesListResponse.interface';
import PropertiesMapResponse from '@/interfaces/responses/propertiesMapResponse.interface';
import PropertyEstimateExistResponse from '@/interfaces/responses/PropertyEstimateExistsResponse.interface';
import PropertyEstimateError from '@/interfaces/errors/PropertyEstimateExistsError.interface';
import Filter from '@/interfaces/filter.interface';
import KeyValue from '@/interfaces/keyValue.interface';
import BrokersMap from '@/models/brokersMap.model';
import EstimateRequest from '@/models/estimateRequest.model';
import FavoritesMap from '@/models/favoritesMap.model';
import NeighborhoodsMap from '@/models/neighborhoodsMap.model';
import MapRoute from '@/interfaces/mapRoute.interface';
import PropertiesList from '@/models/propertiesList.model';
import PropertiesMap from '@/models/propertiesMap.model';
import NewsletterResponse from '@/interfaces/responses/newsletterResponse.interface';
import EmployeesByZipcodeResponse from '@/interfaces/responses/employeesByZipcodeResponse.interface';
import LeadResponse from '@/interfaces/responses/LeadResponse.interface';
import Lead from '@/interfaces/leads/lead.interface';
import FavoritePropertiesResponse from '@/interfaces/responses/favoritePropertiesResponse.interface';
import CustomerResponse from '@/interfaces/responses/customerResponse.interface';
import BuyerDirectoryLead from '@/interfaces/buyerDirectoryLead.interface';
import BuyerDirectoryLeadResponse from '@/interfaces/responses/buyerDirectoryLeadResponse.interface';
import GaPageViewsResponse from '@/interfaces/responses/gaPageViewsResponse.interface';
import PresentationFavoritsAndDocumentsResponse from '@/interfaces/responses/presentationFavoritsAndDocuments.interface';
import GalleryItemsResponse from '@/interfaces/responses/galleryItemsResponse.interface';
import POI from '@/interfaces/POI.interface';
import PoiResponse from '@/interfaces/responses/poiResponse.interface';
import Format from '@/functions/format';
import SearchAgentLead from '@/interfaces/searchAgentLead.interface';
import FavoriteProperty from '@/interfaces/favoriteProperty.interface';

const viaMapToken =
    'eyJkcGZ4IjogImRhbmJvbGlnIiwgInJlZiI6ICIyIiwgInBhciI6ICIiLCAicHJpdnMiOiAicjFaMHIwRjBrNkJ0V3FRY09VeWtCL3k2U1VwSnYyUWJneVhldHE0SFk0WE8vM1lyVzArSzl3PT0ifQ.eZz78ftxWpMxLzdpyGCn0q3NWj4lDFuXS0uQd4QrYxLQM1lpoev7JJWGInZn8s9kBrA02dI7IFKLL5Z6/Ag6mw';
const viaMapBaseUrl = 'https://danbolig.poi.viamap.net';

export default {
    /**
     * Increment the article view count
     *
     * @param {object} object
     * @return {void}
     */
    incrementArticleViewCount({
        pageId,
    }: {
        pageId: number;
    }): Promise<ArticleViewCountResponse> {
        return new Promise((resolve, reject): void => {
            axios
                .post('/api/v1/newspage/updateviewcount', {
                    pageId,
                })
                .then((response: AxiosResponse): void => {
                    resolve({
                        result: response?.data,
                    });
                })
                .catch((): void => {
                    reject();
                });
        });
    },

    /**
     * Fetch list of brokers for a list view.
     *
     * @return {EmployeesByZipcodeResponse}
     */
    getEmployeeByZipcode({
        zipcode,
    }: {
        zipcode: number;
    }): Promise<EmployeesByZipcodeResponse> {
        return new Promise((resolve, reject): void => {
            axios
                .get(`/api/v1/brokers/getemployeebyzipcode/${zipcode}`)
                .then((response: AxiosResponse): void => {
                    resolve({
                        data: response.data,
                    });
                })
                .catch((): void => {
                    reject();
                });
        });
    },

    /**
     * Fetch list of brokers for a list view.
     *
     * @return {EmployeesByZipcodeResponse}
     */
    getResponsibleBroker({
        addressId,
        zipCode,
    }: {
        addressId: string;
        zipCode: string;
    }): Promise<EmployeesByZipcodeResponse> {
        return new Promise((resolve, reject): void => {
            axios
                .get(
                    `/api/v1/estimate/getresponsiblebroker?addressId=${addressId}&zipCode=${zipCode}`,
                )
                .then((response: AxiosResponse): void => {
                    resolve({
                        data: response.data,
                    });
                })
                .catch((): void => {
                    reject();
                });
        });
    },

    /**
     * Fetch list of brokers for a map view.
     *
     * @return {BrokersMapResponse}
     */
    getBrokers({ query }: { query: string }): Promise<BrokersMapResponse> {
        const url = `/api/v1/brokers/list?${query ? `searchQuery=${query}` : ''}`;
        return new Promise((resolve, reject): void => {
            axios
                .get(url)
                .then((response: AxiosResponse): void => {
                    resolve(new BrokersMap(response.data));
                })
                .catch((): void => {
                    reject();
                });
        });
    },

    /**
     * Generate a cancellation token.
     *
     * @param {CancelTokenSource} cancelSource
     * @return {any}
     */
    getCancelToken(cancelSource?: CancelTokenSource): any {
        if (cancelSource) {
            cancelSource.cancel();
        }

        const { cancel, token } = axios.CancelToken.source();

        return {
            cancel,
            token,
        };
    },

    /**
     * Fetch list of POIs for the map
     *
     * @return {PoiResponse}
     */
    getPOIs({
        bBox,
        poiTypes,
    }: {
        bBox: string;
        poiTypes: string[][];
        lat: number;
        lng: number;
        radius?: number;
        zoom: number;
    }): Promise<PoiResponse> {
        return new Promise((resolve, reject): void => {
            const url = `${viaMapBaseUrl}/v1/getpoi/?token=${viaMapToken}&poitypes=${poiTypes}&bbox=${bBox}`;

            axios
                .get(url)
                .then((response: AxiosResponse): void => {
                    let returnData = response.data;

                    /*
                        User Story 58659: POI tilpasning: "Kyst" skal kun vælge nærmeste i stedet for alle
                    */
                    if ('coast' in returnData) {
                        const numberOfCoastPOIsToShow = 1;

                        if (
                            returnData.coast.numfound >= numberOfCoastPOIsToShow
                        ) {
                            returnData.coast.numfound = numberOfCoastPOIsToShow;
                            returnData.coast.POIs = returnData.coast.POIs.slice(
                                0,
                                numberOfCoastPOIsToShow,
                            );
                        }
                    }
                    /*
                    END User Story 58659
                    */

                    resolve({
                        data: returnData,
                    });
                })
                .catch((): void => {
                    reject();
                });
        });
    },
    /**
     * Fetch list of POIs for the map
     *
     * @return {EmployeesByZipcodeResponse}
     */
    getPOIroute({
        decodepolyline = false,
        lat,
        lng,
        poiCoordinate,
        methodOfTransport,
        zoomLevel = 14,
    }: {
        decodepolyline?: boolean;
        lat: number;
        lng: number;
        methodOfTransport: string;
        poiCoordinate: POI;
        zoomLevel: number;
    }): Promise<MapRoute[]> {
        return new Promise((resolve, reject): void => {
            axios
                .get(
                    `${viaMapBaseUrl}/v1/router/?token=${viaMapToken}&fromlatlng=${lat}%2C${lng}&tolatlngs=${poiCoordinate.poilatlng[0]}%2C${poiCoordinate.poilatlng[1]}&mot=${methodOfTransport}${decodepolyline ? '&decodepolyline=' : ''}&zoomlevel=${isNaN(zoomLevel) ? 14 : zoomLevel}`,
                )
                .then((response: AxiosResponse): void => {
                    resolve(response.data);
                })
                .catch((): void => {
                    reject();
                });
        });
    },

    /**
     * Generate a cancellation token for Properties Map.
     *
     * @param {CancelTokenSource} cancelSource
     * @return {any}
     */
    getPropertiesMapCancelToken(cancelSource?: CancelTokenSource): any {
        if (cancelSource) {
            cancelSource.cancel();
        }

        const { cancel, token } = axios.CancelToken.source();

        return {
            cancel,
            token,
        };
    },

    /**
     * Generate a cancellation token for Properties List.
     *
     * @param {CancelTokenSource} cancelSource
     * @return {any}
     */
    getPropertiesListCancelToken(cancelSource?: CancelTokenSource): any {
        if (cancelSource) {
            cancelSource.cancel();
        }

        const { cancel, token } = axios.CancelToken.source();

        return {
            cancel,
            token,
        };
    },

    /**
     * Get info about user estimate.
     *
     * @param {object} object
     * @return {EstimateRequestResponse}
     */
    getEstimateRequest({
        addressId,
        cancelToken,
        price,
        pagePath,
    }: {
        addressId: string;
        cancelToken?: CancelTokenSource;
        price: number;
        pagePath: string;
    }): Promise<EstimateRequestResponse> {
        return new Promise((resolve, reject): void => {
            axios
                .post(
                    '/api/v1/estimate/request',
                    {
                        addressId,
                        pagePath,
                        price,
                    },
                    { cancelToken: cancelToken?.token },
                )
                .then((response: AxiosResponse): void => {
                    resolve(new EstimateRequest(response.data));
                })
                .catch((): void => {
                    reject();
                });
        });
    },

    /**
     * Fetch list of favorites for a map view.
     *
     * @param {object} object
     * @return {FavoritesMapResponse}
     */
    getFavoritesMap({
        lat,
        lng,
        distance = 10,
        neighborhoodId,
        numberOfFavorites = 10,
    }: {
        lat?: number;
        lng?: number;
        distance?: number;
        neighborhoodId?: number;
        numberOfFavorites?: number;
    }): Promise<FavoritesMapResponse> {
        const baseEndpoint = '/api/v1/favoriteplaces';
        let endpointWithQueryParameters: string;

        if (neighborhoodId) {
            endpointWithQueryParameters = `${baseEndpoint}/neighbourhood?neighbourhoodId=${neighborhoodId}`;
        } else {
            endpointWithQueryParameters = `${baseEndpoint}/radius?point=${lng} ${lat}&distance=${distance}&top=${numberOfFavorites}`;
        }

        return new Promise((resolve, reject): void => {
            axios
                .get(endpointWithQueryParameters)
                .then((response: AxiosResponse): void => {
                    resolve(new FavoritesMap(response.data));
                })
                .catch((): void => {
                    reject();
                });
        });
    },

    /**
     * Fetch list of neighborhoods for a map view.
     *
     * @param {object} object
     * @return {NeighborhoodsMapResponse}
     */
    getNeighborhoodsMap({
        bounds,
        neighborhoodId,
        municipalityId,
        zoom,
    }: {
        bounds?: number[][];
        neighborhoodId?: number;
        municipalityId?: number;
        zoom: number;
    }): Promise<NeighborhoodsMapResponse> {
        return new Promise((resolve, reject): void => {
            let filtersArray: KeyValue[] = [];

            if (municipalityId) {
                filtersArray.push({
                    key: 'District',
                    value: municipalityId,
                });
            }

            if (neighborhoodId) {
                filtersArray.push({
                    key: 'Neighbourhood',
                    value: neighborhoodId,
                });
            }

            axios
                .post('/api/v1/neighbourhoods/map', {
                    bounds,
                    filters: filtersArray,
                    zoomLevel: Math.floor(zoom ?? 7), // TODO: change to allow floats or remove
                })
                .then((response: AxiosResponse): void => {
                    resolve(new NeighborhoodsMap(response.data));
                })
                .catch((): void => {
                    reject();
                });
        });
    },

    getGalleryMedia({
        propertyId,
        brokerId,
    }: {
        propertyId: string;
        brokerId: string;
    }): Promise<GalleryItemsResponse> {
        const params = `propertyId=${propertyId}&brokerId=${brokerId}`;
        const url = `/api/v1/properties/galleryMedia?${params}`;
        return new Promise((resolve, reject): void => {
            axios
                .get(url)
                .then((response: AxiosResponse): void => {
                    resolve({
                        results: response.data,
                    });
                })
                .catch((): void => {
                    reject();
                });
        });
    },

    /**
     * Fetch list of properties for a list view.
     *
     * @param {object} object
     * @return {PropertiesListResponse}
     */
    getPropertiesList({
        area,
        bounds,
        filters,
        order,
        page,
        query,
        propertiesListCancelToken,
    }: {
        area?: number[][];
        bounds?: number[][];
        filters?: Filter[];
        order?: string;
        page?: number;
        query?: string;
        propertiesListCancelToken?: CancelTokenSource;
    }): Promise<PropertiesListResponse> {
        return new Promise((resolve, reject): void => {
            let filtersArray: KeyValue[] = [];

            if (filters && filters.length) {
                filtersArray = filters.map((filter: Filter): KeyValue => {
                    return {
                        key: filter.key,
                        value: filter.value,
                    };
                });
            }

            if (query) {
                filtersArray.push({
                    key: 'SearchString',
                    value: query ?? '',
                });
            }

            if (bounds && bounds.length) {
                let boundsValue = 'POLYGON((';
                bounds.forEach((item: number[], index: number): void => {
                    boundsValue += `${item[0]} ${item[1]}`;

                    if (index + 1 < bounds.length) {
                        boundsValue += ', ';
                    }
                });
                boundsValue += '))';

                filtersArray.push({
                    key: 'Viewport',
                    value: boundsValue,
                    // value: bounds, // TODO: switch to array
                });
            }

            if (area && area.length) {
                filtersArray.push({
                    key: 'Polygon',
                    value: Format.polygonHelper(area),
                    // value: area, // TODO: switch to array
                });
            }

            axios
                .post(
                    '/api/v1/properties/list',
                    {
                        filters: filtersArray ?? [],
                        orderBy: order ?? 'relevant',
                        page: page ?? 1,
                    },
                    { cancelToken: propertiesListCancelToken?.token },
                )
                .then((response: AxiosResponse): void => {
                    resolve(new PropertiesList(response.data));
                })
                .catch((e): void => {
                    if (axios.isCancel(e)) {
                        reject(e);
                    }
                    reject(new Error('Oops!'));
                });
        });
    },

    /**
     * Fetch list of properties for a map view.
     *
     * @param {object} object
     * @return {PropertiesMapResponse}
     */
    getPropertiesMap({
        area,
        bounds,
        filters,
        neighborhoodId,
        order,
        query,
        zoom,
        propertiesMapCancelToken,
    }: {
        area?: number[][];
        bounds?: number[][];
        filters?: Filter[];
        neighborhoodId?: number;
        order?: string;
        query?: string;
        zoom?: number;
        propertiesMapCancelToken?: CancelTokenSource;
    }): Promise<PropertiesMapResponse> {
        return new Promise((resolve, reject): void => {
            let filtersArray: KeyValue[] = [];

            if (filters && filters.length) {
                filtersArray = filters.map((filter: Filter): KeyValue => {
                    return {
                        key: filter.key,
                        value: filter.value,
                    };
                });
            }

            if (query) {
                filtersArray.push({
                    key: 'SearchString',
                    value: query ?? '',
                });
            }

            if (area && area.length) {
                filtersArray.push({
                    key: 'Polygon',
                    value: Format.polygonHelper(area),
                });
            }

            if (neighborhoodId) {
                filtersArray.push({
                    key: 'Neighbourhood',
                    value: neighborhoodId, // TODO: hook up in backend
                });
            }

            axios
                .post(
                    '/api/v1/properties/map',
                    {
                        bounds: bounds ?? [],
                        filters: filtersArray ?? [],
                        maxResults: 1000, // TODO: remove
                        orderBy: order ?? 'relevant',
                        zoomLevel: Math.floor(zoom ?? 7), // TODO: change to allow floats or remove
                    },
                    { cancelToken: propertiesMapCancelToken?.token },
                )
                .then((response: AxiosResponse): void => {
                    resolve(new PropertiesMap(response.data));
                })
                .catch((e): void => {
                    if (axios.isCancel(e)) {
                        reject(e);
                    }
                    reject(new Error('Oops!'));
                });
        });
    },

    /**
     * Post a property to check if estimate exists.
     *
     * @param {object} object
     * @return {PropertyEstimateResponse}
     */
    hasPropertyEstimate({
        addressId,
    }: {
        addressId: string;
    }): Promise<PropertyEstimateExistResponse | PropertyEstimateError> {
        return new Promise((resolve, reject): void => {
            axios
                .post('/api/v1/estimate/hasestimate', {
                    addressId,
                })
                .then((response: AxiosResponse): void => {
                    resolve({
                        exists: response?.data,
                    });
                })
                .catch((error: AxiosError): void => {
                    reject({
                        error: (error as any)?.response?.data?.error ?? '',
                    });
                });
        });
    },

    /**
     * Send neighborhood local votes to be updated.
     *
     * @param {object} object
     * @return {NeighborhoodLocalVotesResponse}
     */
    postNeighborhoodLocalVotes({
        neighborhoodId,
        votes,
    }: {
        neighborhoodId: number;
        votes: number[];
    }): Promise<NeighborhoodLocalVotesResponse> {
        return new Promise((resolve, reject): void => {
            axios
                .post('/api/v1/neighbourhoods/localsay', {
                    FeatureIds: votes,
                    NeighbourhoodId: neighborhoodId,
                })
                .then((response: AxiosResponse): void => {
                    resolve({
                        completed: response.status === 200,
                    });
                })
                .catch((): void => {
                    reject();
                });
        });
    },

    /**
     * Send mindworking lead.
     *
     * @param {object} object
     * @return {SalesValuationLeadResponse}
     */
    validateLead(
        lead: Lead,
        excludeValidation?: string[],
    ): Promise<LeadResponse> {
        return new Promise((resolve, reject): void => {
            axios
                .post('/api/v1/queue/validatelead', {
                    lead,
                    excludeValidation,
                })
                .then((response: AxiosResponse): void => {
                    resolve({
                        added: response.status === 200,
                    });
                })
                .catch((error: AxiosError): void => {
                    reject({
                        errors: (error as any)?.response?.data?.errors ?? [],
                    });
                });
        });
    },

    /**
     * Send mindworking lead.
     *
     * @param {object} object
     * @return {SalesValuationLeadResponse}
     */
    postLead(lead: Lead, excludeValidation?: string[]): Promise<LeadResponse> {
        return new Promise((resolve, reject): void => {
            axios
                .post('/api/v1/queue/addlead', {
                    lead,
                    excludeValidation,
                })
                .then((response: AxiosResponse): void => {
                    resolve({
                        added: response.status === 200,
                    });
                })
                .catch((error: AxiosError): void => {
                    reject({
                        errors: (error as any)?.response?.data?.errors ?? [],
                    });
                });
        });
    },

    /**
     * Send buyer directory lead.
     *
     * @param {BuyerDirectoryLead} lead
     * @return {BuyerDirectoryLeadResponse}
     */
    postBuyerDirectoryLead(
        lead: BuyerDirectoryLead | SearchAgentLead,
    ): Promise<BuyerDirectoryLeadResponse> {
        return new Promise((resolve, reject): void => {
            axios
                .post('/api/v1/buyerdirectory/add', lead)
                .then((response: AxiosResponse): void => {
                    resolve({
                        result: response.data,
                    });
                })
                .catch((error: AxiosError): void => {
                    reject({
                        errors: (error as any)?.response?.data?.errors ?? [],
                    });
                });
        });
    },

    /**
     * Validate buyer directory lead.
     *
     * @param {BuyerDirectoryLead} lead
     * @return {BuyerDirectoryLeadResponse}
     */
    validateBuyerDirectoryLead(
        lead: BuyerDirectoryLead | SearchAgentLead,
    ): Promise<BuyerDirectoryLeadResponse> {
        return new Promise((resolve, reject): void => {
            axios
                .post('/api/v1/buyerdirectory/validate', lead)
                .then((response: AxiosResponse): void => {
                    resolve({
                        result: response.data,
                    });
                })
                .catch((error: AxiosError): void => {
                    reject({
                        errors: (error as any)?.response?.data?.errors ?? [],
                    });
                });
        });
    },

    /**
     * Get favorites property
     *
     * @param {object} object
     * @return {void}
     */
    getFavoriteProperties(): Promise<FavoritePropertiesResponse> {
        return new Promise((resolve, reject): void => {
            axios
                .get('/api/v1/customerfavorite/customerfavorites')
                .then((response: AxiosResponse): void => {
                    resolve({
                        properties: response?.data,
                    });
                })
                .catch((): void => {
                    reject();
                });
        });
    },

    addToFavoriteProperties(
        payload: Pick<
            FavoriteProperty,
            'propertyId' | 'brokerId' | 'isDanbolig'
        >,
    ): Promise<FavoritePropertiesResponse> {
        return new Promise((resolve, reject): void => {
            axios
                .post('/api/v1/customerfavorite/AddToFavorites', payload)
                .then((response: AxiosResponse): void => {
                    resolve({
                        properties: response?.data,
                    });
                })
                .catch((error: AxiosError): void => {
                    reject(`error: ${error}`);
                });
        });
    },

    removeFromFavoriteProperties(
        payload: Pick<
            FavoriteProperty,
            'propertyId' | 'brokerId' | 'isDanbolig'
        >,
    ): Promise<FavoritePropertiesResponse> {
        return new Promise((resolve, reject): void => {
            axios
                .post('/api/v1/customerfavorite/DeleteFavorite', payload)
                .then((response: AxiosResponse): void => {
                    resolve({
                        properties: response?.data,
                    });
                })
                .catch((error: AxiosError): void => {
                    reject(`error: ${error}`);
                });
        });
    },

    /**
     * Increment the document download count for a single property
     *
     * @param {object} object
     * @return {void}
     */
    getCustomer(): Promise<CustomerResponse> {
        return new Promise((resolve, reject): void => {
            axios
                .get('/api/v1/customerservice/customer')
                .then((response: AxiosResponse): void => {
                    resolve({
                        customer: response?.data,
                    });
                })
                .catch((): void => {
                    reject();
                });
        });
    },

    /**
     * Get number of pageviews from Google Analytics.
     *
     * @param {object} object
     * @return {GaPageViewsResponse}
     */
    getGaPageViews({
        propertyId,
        brokerId,
        days,
    }: {
        propertyId: string;
        brokerId: string;
        days: number;
    }): Promise<GaPageViewsResponse> {
        return new Promise((resolve, reject): void => {
            const params = `propertyId=${propertyId}&brokerId=${brokerId}&days=${days}`;
            const url = `/api/v1/properties/pageviews?${params}`;
            axios
                .get(url)
                .then((response: AxiosResponse): void => {
                    resolve({
                        result: response?.data,
                    });
                })
                .catch((error: AxiosError): void => {
                    reject({
                        errors: (error as any)?.response?.data?.errors ?? [],
                    });
                });
        });
    },

    /**
     * Get number of favorites and documents downloaded on a presentation
     *
     * @param {object} object
     * @return {PresentationFavoritsAndDocumentsResponse}
     */
    getPresentationFavouritsAndDocuments({
        propertyId,
        brokerId,
    }: {
        propertyId: string;
        brokerId: string;
    }): Promise<PresentationFavoritsAndDocumentsResponse> {
        return new Promise((resolve, reject): void => {
            const params = `propertyId=${propertyId}&brokerId=${brokerId}`;
            const url = `/api/v1/properties/stats?${params}`;
            axios
                .get(url)
                .then((response: AxiosResponse): void => {
                    resolve({
                        result: response?.data,
                    });
                })
                .catch((error: AxiosError): void => {
                    reject({
                        errors: (error as any)?.response?.data?.errors ?? [],
                    });
                });
        });
    },

    /**
     * Increment the document download count for a single property
     *
     * @param {object} object
     * @return {void}
     */
    newsletterSignup({
        email,
        name,
        municipality,
        consent,
    }: {
        email: string;
        name: string;
        municipality: string;
        consent: boolean;
    }): Promise<NewsletterResponse> {
        return new Promise((resolve, reject): void => {
            axios
                .post('/api/v1/newsletter/signup', {
                    name,
                    email,
                    municipality,
                    consent,
                })
                .then((response: AxiosResponse): void => {
                    resolve({
                        result: response?.data,
                    });
                })
                .catch((error: AxiosError): void => {
                    reject({
                        errors: (error as any)?.response?.data?.errors ?? [],
                    });
                });
        });
    },

    getGalleryMediaByCaseNo({
        caseNo,
    }: {
        caseNo: string;
    }): Promise<GalleryItemsResponse> {
        const params = `caseNo=${caseNo}`;
        const url = `/api/v1/project/gallerymediabycaseno?${params}`;
        return new Promise((resolve, reject): void => {
            axios
                .get(url)
                .then((response: AxiosResponse): void => {
                    resolve({
                        results: response.data,
                    });
                })
                .catch((): void => {
                    reject();
                });
        });
    },
};
